<template>
    <v-container
        v-if="form"
        tag="form"
        class="py-4 px-6"
    >
        <v-subheader class="text-uppercase font-weight-bold pl-0">Anagrafica</v-subheader>
        <v-row dense>
            <v-col
                cols="12"
            >
                <v-text-field
                    v-model.trim="ragioneSociale"
                    :error-messages="ragioneSocialeErrors"
                    label="Ragione sociale *"
                    outlined
                    required
                    @blur="$v.ragioneSociale.$touch()"
                    @input="$v.ragioneSociale.$touch()"
                />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <v-text-field
                    v-model.trim="nome"
                    :error-messages="nomeErrors"
                    clearable
                    label="Nome"
                    outlined
                    @blur="$v.nome.$touch()"
                    @input="$v.nome.$touch()"
                />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <v-text-field
                    v-model.trim="cognome"
                    :error-messages="cognomeErrors"
                    clearable
                    label="Cognome"
                    outlined
                    @blur="$v.cognome.$touch()"
                    @input="$v.cognome.$touch()"
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model.trim="indirizzo"
                    :append-icon="hasGeolocation ? (gps ? 'mdi-crosshairs-gps' : 'mdi-crosshairs') : null"
                    :error-messages="indirizzoErrors"
                    :hint="'Coordinate GPS: ' + (gps || 'assenti')"
                    :prepend-icon="appOnline ? $options.filters.mdi('mdiMapSearch') : null"
                    label="Indirizzo *"
                    outlined
                    persistent-hint
                    required
                    @blur="$v.indirizzo.$touch()"
                    @input="$v.indirizzo.$touch()"
                    @click:prepend="dialogGoogleMapAutocomplete = true"
                    @click:append="getGpsCoords"
                    :append-outer-icon="(addressDetailsOpen ? 'mdiChevronUp' : 'mdiChevronDown') | mdi"
                    @click:append-outer="addressDetailsOpen = !addressDetailsOpen"
                />
            </v-col>

            <v-col
                v-if="addressDetailsOpen"
                cols="12"
            >
                <v-sheet
                    class="px-4 pt-5 mb-4"
                    outlined
                    rounded
                >
                    <v-row dense>
                        <v-col
                            cols="12"
                            md="3"
                        >
                            <v-autocomplete
                                v-model="nazione"
                                :error-messages="nazioneErrors"
                                :items="countries"
                                clearable
                                item-text="name"
                                item-value="code"
                                label="Nazione"
                                outlined
                                @blur="$v.nazione.$touch()"
                                @input="$v.nazione.$touch()"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="3"
                        >
                            <v-text-field
                                v-model.trim="cap"
                                :error-messages="capErrors"
                                clearable
                                label="C.A.P."
                                outlined
                                @blur="$v.cap.$touch()"
                                @input="$v.cap.$touch()"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model.trim="citta"
                                :error-messages="cittaErrors"
                                clearable
                                label="Città"
                                outlined
                                @blur="$v.citta.$touch()"
                                @input="$v.citta.$touch()"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-autocomplete
                                v-model.trim="regione"
                                :disabled="nazione !== 'IT'"
                                :error-messages="regioneErrors"
                                :items="regioni"
                                clearable
                                label="Regione"
                                outlined
                                @blur="$v.regione.$touch()"
                                @input="$v.regione.$touch()"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-autocomplete
                                v-model="provincia"
                                :disabled="nazione !== 'IT' || !regione"
                                :error-messages="provinciaErrors"
                                :items="provincie"
                                clearable
                                item-text="nome"
                                item-value="sigla"
                                label="Provincia"
                                outlined
                                @blur="$v.provincia.$touch()"
                                @input="$v.provincia.$touch()"
                            />
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-col>

            <v-col
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model.trim="pIva"
                    :error-messages="pIvaErrors"
                    label="P.IVA *"
                    outlined
                    required
                    @blur="$v.pIva.$touch()"
                    @input="$v.pIva.$touch()"
                />
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model.trim="codiceFiscale"
                    :error-messages="codiceFiscaleErrors"
                    clearable
                    label="Codice fiscale"
                    outlined
                    @blur="$v.codiceFiscale.$touch()"
                    @input="$v.codiceFiscale.$touch()"
                />
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model.trim="pIvaEstero"
                    :error-messages="pIvaEsteroErrors"
                    clearable
                    hint="Secondaria o estera"
                    label="P.IVA"
                    outlined
                    persistent-hint
                    @blur="$v.pIvaEstero.$touch()"
                    @input="$v.pIvaEstero.$touch()"
                />
            </v-col>


            <v-col cols="12">
                <v-text-field
                    v-model.trim="note"
                    :error-messages="noteErrors"
                    clearable
                    counter="255"
                    label="Altre informazioni"
                    outlined
                    @blur="$v.note.$touch()"
                    @input="$v.note.$touch()"
                />
            </v-col>
        </v-row>

        <v-subheader class="text-uppercase font-weight-bold pl-0">Contatti telefonici</v-subheader>
        <v-simple-table class="registries-edit-table" dense>
            <tfoot>
            <tr>
                <td colspan="2"/>
                <td class="text-right">
                    <v-btn
                        @click="addPhone()"
                        icon>
                        <v-icon color="green">mdi-plus-box-outline</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tfoot>
            <tbody>
            <tr
                v-for="i in Object.keys(contacts.phones)"
                :key="`PHONE-${i}`"
            >
                <td style="width: 55%">
                    <v-text-field
                        v-model.trim="contacts.phones[i].value"
                        autocomplete="tel"
                        label="Numero *"
                        required
                    />
                </td>
                <td style="width: 40%;">
                    <v-text-field
                        v-model.trim="contacts.phones[i].label"
                        append-icon="mdi-view-list-outline"
                        label="Etichetta *"
                        required
                        @click:append="getFromPhonesTypologiesList(i)"
                    />
                </td>
                <td class="text-right" style="width: 5%">
                    <v-btn
                        v-if="contacts.phones.length > 1"
                        @click="deletePhone(i)"
                        icon>
                        <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </v-simple-table>

        <v-subheader class="text-uppercase font-weight-bold pl-0">Indirizzi e-mail</v-subheader>
        <v-simple-table class="registries-edit-table" dense>
            <tfoot>
            <tr>
                <td colspan="2"/>
                <td class="text-right">
                    <v-btn
                        @click="addEmail()"
                        icon
                    >
                        <v-icon color="green">mdi-plus-box-outline</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tfoot>
            <tbody>
            <tr
                v-for="i in Object.keys(contacts.emails)"
                :key="`EMAIL-${i}`"
            >
                <td style="width: 55%">
                    <v-text-field
                        v-model.trim="contacts.emails[i].value"
                        label="E-Mail *"
                        required
                    />
                </td>
                <td style="width: 40%;">
                    <v-text-field
                        v-model.trim="contacts.emails[i].label"
                        append-icon="mdi-view-list-outline"
                        label="Etichetta *"
                        required
                        @click:append="getFromEmailsTypologiesList(i)"
                    />

                </td>
                <td class="text-right" style="width: 5%">
                    <v-btn
                        v-if="contacts.emails.length > 1"
                        @click="deleteEmail(i)"
                        icon
                    >
                        <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </v-simple-table>

        <v-subheader class="text-uppercase font-weight-bold pl-0">Altre informazioni</v-subheader>
        <v-simple-table class="registries-edit-table" dense>
            <tfoot>
                <tr>
                    <td colspan="2"></td>
                    <td class="text-right">
                        <v-btn
                            @click="addDetails()"
                            icon
                        >
                            <v-icon color="green">mdi-plus-box-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tfoot>
            <tbody>
            <tr
                v-for="i in Object.keys(details)"
                :key="`DETAIL-${i}`"
            >
                <td style="width: 55%">
                    <v-text-field
                        v-model.trim="details[i].keyName"
                        append-icon="mdi-view-list-outline"
                        label="Specifica *"
                        required
                        @click:append="getFromDetailsList(i)"
                    />
                </td>
                <td style="width: 40%;">
                    <v-text-field
                        v-model.trim="details[i].keyValue"
                        label="Dettaglio *"
                        required
                    />
                </td>
                <td
                    class="text-right"
                    style="width: 5%"
                >
                    <v-btn
                        @click="deleteDetails(i)"
                        icon
                    >
                        <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                </td>
            </tr>
            </tbody>
        </v-simple-table>

        <v-subheader class="text-uppercase font-weight-bold pl-0">Apparati aziendali</v-subheader>
        <v-row
            v-for="i in Object.keys(assets)"
            :key="`ASSET-${i}`"
            dense
        >
            <v-col
                cols="4"
                md="3"
                xs="4"
            >
                <v-text-field
                    v-model.trim="assets[i].line"
                    label="Linea"
                />
            </v-col>
            <v-col
                cols="5"
                md="2"
                xs="5"
            >
                <v-text-field
                    v-model.trim="assets[i].typology"
                    append-icon="mdi-view-list-outline"
                    label="Tipologia"
                    @click:append="getFromAssetsTypologiesList(i)"
                />
            </v-col>
            <v-col
                cols="3"
                md="1"
                xs="3"
            >
                <v-text-field
                    v-model.number="assets[i].qty"
                    label="Q.tà"
                    type="number"
                />
            </v-col>
            <v-col
                cols="5"
                md="2"
                xs="5"
            >
                <v-text-field
                    v-model.trim="assets[i].brand"
                    label="Marca"
                />
            </v-col>
            <v-col
                cols="5"
                md="3"
                xs="5"
            >
                <v-text-field
                    v-model.trim="assets[i].model"
                    label="Modello"
                />
            </v-col>
            <v-col
                class="text-center"
                cols="2"
                md="1"
                xs="2"
            >
                <v-icon
                    @click="deleteAsset(i)"
                    color="red"
                >
                    mdi-delete
                </v-icon>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col
                class="text-center"
                cols="2"
                offset="10"
            >
                <v-icon
                    color="green"
                    @click="addAsset()"
                >
                    mdi-plus-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <v-subheader class="text-uppercase font-weight-bold pl-0">Tags</v-subheader>
        <v-row dense>
            <v-col
                v-for="i in Object.keys(tags)"
                :key="`TAG-${i}`"
                cols="6"
                md="2"
                sm="4"
            >
                <v-text-field
                    v-model.trim="tags[i].text"
                    append-icon="mdi-view-list-outline"
                    append-outer-icon="mdi-close"
                    label="Tag"
                    @click:append="getFromTagsList(i)"
                    @click:append-outer="deleteTag(i)"
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col
                class="text-center"
                cols="2"
                offset="10"
            >
                <v-icon
                    color="green"
                    @click="addTag()"
                >
                    mdi-plus-box-outline
                </v-icon>
            </v-col>
        </v-row>

        <portal to="navigation-back">
            <v-btn
                icon
                @click="close"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </portal>

        <portal to="commands">
            <v-btn
                icon
                title="Reset"
                @click="fill"
            >
                <v-icon dark>mdi-reload</v-icon>
            </v-btn>

            <v-btn
                icon
                title="Salva"
                @click="save"
            >
                <v-icon dark>mdi-content-save-outline</v-icon>
            </v-btn>
        </portal>

        <GlobalDialogListSuggestions
            v-if="dialogSuggestions"
            v-bind:items="dialogSuggestionsItems"
            v-bind:title="dialogSuggestionsTitle"
            v-on:close="dialogSuggestions = false"
            v-on:select="dialogSuggestionsSelect"
        />

        <GlobalOverlayFullscreenIndeterminate
            v-if="saving"
            v-bind:caption="savingCaption"
        />

        <SuggestAddresses
            v-if="dialogAddressSuggestion"
            v-bind:addresses="dialogAddressSuggestionAddresses"
            v-on:close="dialogAddressSuggestion = false"
            v-on:select="applyAddressSuggestion"
        />

        <GlobalDialogGoogleMapsAutocomplete
            v-if="dialogGoogleMapAutocomplete"
            v-on:close="dialogGoogleMapAutocomplete = false"
            v-on:select="applyGoogleMapAutocomplete"
        />
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'
import { filter, groupBy, includes, orderBy, reduce } from 'lodash'
import { mapActions, mapState } from 'vuex'
import axios from "../../libraries/axios"
import countries from '../../assets/json/geo/countries.json'
import provincie from '../../assets/json/geo/italia/province.json'
import assetTypologies from '../../assets/json/suggestions/asset/typologies.json'
import emailTypologies from '../../assets/json/suggestions/email/typologies.json'
import phoneTypologies from '../../assets/json/suggestions/phone/typologies.json'
import { Suggestions } from "../../models/_all"
import GlobalDialogGoogleMapsAutocomplete from "../../components/Global/Dialog/Google/Maps/Autocomplete"
import GlobalDialogListSuggestions from "../../components/Global/Dialog/List/Suggestions"
import GlobalOverlayFullscreenIndeterminate from "../../components/Global/Overlay/Fullscreen/Indeterminate"
import SuggestAddresses from '../../components/Registries/Dialog/SuggestAddresses'
import { Portal } from "portal-vue";

export default {
    beforeMount() {
        this.$store.commit('setToolbarTitle', isNaN(this.id) ? 'Crea' : 'Modifica')
        this.hasGeolocation = (window.navigator.geolocation !== undefined)

        this.fill()
    },

    components: {
        GlobalDialogGoogleMapsAutocomplete,
        GlobalDialogListSuggestions,
        GlobalOverlayFullscreenIndeterminate,
        SuggestAddresses,
        Portal,
    },

    computed: {
        ...mapState({
            appOnline: ({_default}) => _default.appOnline,
        }),

        capErrors() {
            const errors = []

            if (!this.$v.cap.$dirty) {
                return errors
            }

            return errors
        },

        cittaErrors() {
            const errors = []

            if (!this.$v.citta.$dirty) {
                return errors
            }

            return errors
        },

        codiceFiscaleErrors() {
            const errors = []

            if (!this.$v.codiceFiscale.$dirty) {
                return errors
            }

            return errors
        },

        cognomeErrors() {
            const errors = []

            if (!this.$v.cognome.$dirty) {
                return errors
            }

            return errors
        },

        countries() {
            return countries
        },

        data() {
            return {
                assets: this.assets,
                cap: this.cap,
                citta: this.citta,
                codiceFiscale: this.codiceFiscale,
                cognome: this.cognome,
                contacts: this.contacts,
                details: this.details,
                gps: this.gps,
                id: this.id,
                indirizzo: this.indirizzo,
                isProspect: this.isProspect,
                nazione: this.nazione,
                nome: this.nome,
                note: this.note,
                pIva: this.pIva,
                pIvaEstero: this.pIvaEstero,
                payments: this.payments,
                provincia: this.provincia,
                ragioneSociale: this.ragioneSociale,
                regione: this.regione,
                tags: this.tags,
            }
        },

        indirizzoErrors() {
            const errors = []

            if (!this.$v.indirizzo.$dirty) {
                return errors
            }

            !this.$v.indirizzo.required && errors.push('Questo campo è obbligatorio')

            return errors
        },

        nazioneErrors() {
            const errors = []

            if (!this.$v.nazione.$dirty) {
                return errors
            }

            return errors
        },

        nomeErrors() {
            const errors = []

            if (!this.$v.nome.$dirty) {
                return errors
            }

            return errors
        },

        noteErrors() {
            const errors = []

            if (!this.$v.note.$dirty) {
                return errors
            }

            return errors
        },

        pIvaErrors() {
            const errors = []

            if (!this.$v.pIva.$dirty) {
                return errors
            }

            !this.$v.pIva.required && errors.push('Questo campo è obbligatorio');

            return errors
        },

        pIvaEsteroErrors() {
            const errors = []

            if (!this.$v.pIvaEstero.$dirty) {
                return errors
            }

            return errors
        },

        provinciaErrors() {
            const errors = []

            if (!this.$v.provincia.$dirty) {
                return errors
            }

            return errors
        },

        regioni() {
            const regioni = reduce(provincie, (regioni, {regione}) => {
                if (!includes(regioni, regione)) {
                    regioni.push(regione)
                }

                return regioni
            }, [])

            return orderBy(regioni)
        },

        provincie() {
            if (this.regione) {
                const _p = groupBy(provincie, ({regione}) => regione)

                return _p[ this.regione ]
            }

            return []
        },

        ragioneSocialeErrors() {
            const errors = []

            if (!this.$v.ragioneSociale.$dirty) {
                return errors
            }

            !this.$v.ragioneSociale.required && errors.push('Questo campo è obbligatorio')

            return errors
        },

        regioneErrors() {
            const errors = []

            if (!this.$v.regione.$dirty) {
                return errors
            }

            return errors
        },
    },

    data() {
        return {
            addressDetailsOpen: false,
            assets: null,
            banks: null,
            cap: null,
            citta: null,
            codiceFiscale: null,
            cognome: null,
            contacts: null,
            credit: null,
            creditExposure: null,
            creditMargin: null,
            details: null,
            dialogAddressSuggestion: false,
            dialogAddressSuggestionAddresses: null,
            dialogGoogleMapAutocomplete: false,
            dialogSuggestions: false,
            dialogSuggestionsItems: null,
            dialogSuggestionsSelect: null,
            dialogSuggestionsTitle: null,
            form: false,
            gps: null,
            hasGeolocation: false,
            id: null,
            indirizzo: null,
            isProspect: null,
            nazione: null,
            nome: null,
            note: null,
            pIva: null,
            pIvaEstero: null,
            payments: null,
            provincia: null,
            ragioneSociale: null,
            regione: null,
            registry: null,
            reliability: null,
            saving: false,
            savingCaption: null,
            tags: null,
        }
    },

    methods: {
        ...mapActions({
            getRegistry: 'registries/getRegistry',
            put: 'registries/put',
        }),

        async close() {
            if (this.$route.query[ 'from-card' ]) {
                await this.$router.push(`/registries?open=${this.$route.params.id}`)
                    .catch(() => {
                        // I don't what I do
                    })
            } else {
                if (window.history.length > 1) {
                    this.$router.back()
                } else {
                    await this.$router.push('/registries')
                        .catch(() => {
                            // I don't what I do
                        })
                }
            }
        },

        fill() {
            if (this.$route.params.id === undefined) {
                this.registry = {
                    assets: [],
                    cap: null,
                    citta: null,
                    codiceFiscale: null,
                    cognome: null,
                    contacts: {emails: [], phones: []},
                    details: [],
                    gps: null,
                    id: uuidv4(),
                    indirizzo: null,
                    isProspect: true,
                    nazione: null,
                    nome: null,
                    note: null,
                    pIva: null,
                    pIvaEstero: null,
                    payments: [],
                    provincia: null,
                    ragioneSociale: null,
                    regione: null,
                    tags: [],
                }
            } else {
                this.getRegistry(this.$route.params.id)
                    .then(registry => this.registry = registry)
            }
        },

        save() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.savingCaption = 'Salvataggio in corso'

                this.put({
                    id: this.id,
                    recordset: this.data,
                }).then(() => {
                    this.saving = false
                    this.close()
                })
            }
        },

        applyAddressSuggestion(item) {
            this.cap = item.cap
            this.citta = item.citta
            this.indirizzo = item.indirizzo
            this.nazione = item.nazione.toUpperCase()
            this.provincia = item.provincia
            this.regione = item.regione

            this.dialogAddressSuggestion = false
        },
        applyGoogleMapAutocomplete(item) {
            this.cap = item.cap
            this.citta = item.citta
            this.indirizzo = item.indirizzo
            this.nazione = item.nazione
            this.provincia = item.provincia
            this.regione = item.regione
            this.gps = item.gps

            this.dialogGoogleMapAutocomplete = false
        },

        addDetails() {
            this.details.push({
                keyName: '',
                keyValue: '',
            })
        },
        deleteDetails(index) {
            this.details = filter(this.details, (detail, idx) => (idx !== Number(index)))
        },
        async getFromDetailsList(index) {
            this.dialogSuggestionsItems = await Suggestions.find('registries/details')
            this.dialogSuggestionsSelect = e => {
                this.details[ index ].keyName = e
                this.dialogSuggestions = false
            }
            this.dialogSuggestionsTitle = 'Dettagli lavorazione'
            this.dialogSuggestions = true
        },

        addEmail() {
            this.contacts.emails.push({
                label: '',
                value: '',
            })
        },
        deleteEmail(index) {
            this.contacts.emails = filter(this.contacts.emails, (c, idx) => (idx !== Number(index)))
        },
        getFromEmailsTypologiesList(index) {
            this.dialogSuggestionsItems = emailTypologies
            this.dialogSuggestionsSelect = e => {
                this.contacts.emails[ index ].label = e
                this.dialogSuggestions = false
            }
            this.dialogSuggestionsTitle = 'Etichette e-mail'
            this.dialogSuggestions = true
        },

        addPhone() {
            this.contacts.phones.push({
                label: '',
                value: '',
            })
        },
        deletePhone(index) {
            this.contacts.phones = filter(this.contacts.phones, (c, idx) => (idx !== Number(index)))
        },
        getFromPhonesTypologiesList(index) {
            this.dialogSuggestionsItems = phoneTypologies
            this.dialogSuggestionsSelect = e => {
                this.contacts.phones[ index ].label = e
                this.dialogSuggestions = false
            }
            this.dialogSuggestionsTitle = 'Etichette telefono'
            this.dialogSuggestions = true
        },

        addAsset() {
            this.assets.push({
                line: '',
                typology: '',
                qty: '',
                brand: '',
                model: '',
            })
        },
        deleteAsset(index) {
            this.assets = filter(this.assets, (a, idx) => (idx !== Number(index)))
        },
        getFromAssetsTypologiesList(index) {
            this.dialogSuggestionsItems = assetTypologies
            this.dialogSuggestionsSelect = e => {
                this.assets[ index ].typology = e
                this.dialogSuggestions = false
            }
            this.dialogSuggestionsTitle = 'Tipologie apparati'
            this.dialogSuggestions = true
        },

        addTag() {
            this.tags.push({
                text: '',
            })
        },
        deleteTag(index) {
            this.tags = filter(this.tags, (t, idx) => (idx !== Number(index)))
        },
        async getFromTagsList(index) {
            this.dialogSuggestionsItems = await Suggestions.find('registries/tags')
            this.dialogSuggestionsSelect = e => {
                this.tags[ index ].text = e
                this.dialogSuggestions = false
            }
            this.dialogSuggestionsTitle = 'Tags disponibili'
            this.dialogSuggestions = true
        },

        getGpsCoords() {
            if (this.hasGeolocation) {
                window.navigator.geolocation.getCurrentPosition(position => {
                    const {coords} = position
                    this.gps = coords.latitude + ',' + coords.longitude

                    if (this.appOnline) {
                        this.getGpsCoordsReverse()
                    }
                })
            }
        },
        getGpsCoordsReverse() {
            const coords = this.gps.split(',')

            axios.get('https://api.geoapify.com/v1/geocode/reverse', {
                params: {
                    apiKey: process.env.VUE_APP_GEOAPIFY_API_KEY,
                    lang: 'it',
                    lat: coords[ 0 ],
                    lon: coords[ 1 ],
                    limit: 10,
                },
            }).then(({status, data}) => {
                if (status === 200) {
                    const {features} = data

                    this.dialogAddressSuggestionAddresses = reduce(features, (carry, {properties}) => {
                        const {
                            address_line1,
                            address_line2,
                            city,
                            country_code,
                            county_code,
                            postcode,
                            state,
                            street,
                        } = properties

                        carry.push({
                            address_line1,
                            address_line2,
                            cap: postcode,
                            citta: city,
                            indirizzo: street,
                            nazione: country_code,
                            provincia: county_code,
                            regione: state,
                        })

                        return carry
                    }, [])
                }
            })
        },
    },

    mixins: [validationMixin],

    name: "Registries-Edit",

    validations: {
        cap: {
            maxLength: maxLength(255),
        },
        citta: {
            maxLength: maxLength(255),
        },
        codiceFiscale: {
            maxLength: maxLength(255),
        },
        cognome: {
            maxLength: maxLength(255),
        },
        indirizzo: {
            required,
            maxLength: maxLength(255),
        },
        nazione: {
            maxLength: maxLength(255),
        },
        nome: {
            maxLength: maxLength(255),
        },
        note: {
            maxLength: maxLength(255),
        },
        pIva: {
            required,
            maxLength: maxLength(255),
        },
        pIvaEstero: {
            maxLength: maxLength(255),
        },
        provincia: {
            maxLength: maxLength(255),
        },
        ragioneSociale: {
            required,
            maxLength: maxLength(255),
        },
        regione: {
            maxLength: maxLength(255),
        },
    },

    watch: {
        codiceFiscale(data) {
            this.codiceFiscale = data?.toUpperCase()
        },

        dialogAddressSuggestion(status) {
            if (status === false) {
                this.dialogAddressSuggestionAddresses = null
            }
        },

        dialogAddressSuggestionAddresses(addresses) {
            if (addresses !== null) {
                this.dialogAddressSuggestion = true
            }
        },

        dialogSuggestions(status) {
            if (status === false) {
                this.dialogSuggestionsItems = null
                this.dialogSuggestionsSelect = null
                this.dialogSuggestionsTitle = null
            }
        },

        pIva(data) {
            this.pIva = data?.toUpperCase()
        },

        pIvaEstero(data) {
            this.pIvaEstero = data?.toUpperCase()
        },

        registry(collection) {
            if (collection) {
                this.assets = collection.assets
                this.cap = collection.cap
                this.citta = collection.citta
                this.codiceFiscale = collection.codiceFiscale
                this.cognome = collection.cognome
                this.contacts = collection.contacts
                this.details = collection.details
                this.gps = collection.gps
                this.id = collection.id
                this.indirizzo = collection.indirizzo
                this.isProspect = collection.isProspect
                this.nazione = collection.nazione
                this.nome = collection.nome
                this.note = collection.note
                this.pIva = collection.pIva
                this.pIvaEstero = collection.pIvaEstero
                this.provincia = collection.provincia
                this.ragioneSociale = collection.ragioneSociale
                this.regione = collection.regione
                this.tags = reduce(collection.tags, (carry, tag) => {
                    if (tag.id) {
                        carry.push(tag)
                    }

                    return carry
                }, [])

                this.form = true
            }
        },

        saving(status) {
            if (!status) {
                this.savingCaption = null
            }
        },

        savingCaption(caption) {
            if (caption) {
                this.saving = true
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.registries-edit-table {
    margin-left: -4px !important;
    margin-right: -4px !important;

    table tr > td {
        padding: 0 4px !important;
    }
}
</style>
