<template>
    <v-dialog
        v-if="task !== null"
        max-width="450"
        persistent
        value="true"
    >
        <v-card>
            <StandardAppBar
                v-bind:icon="$options.filters.mdi('mdiCalendarLockOutline')"
                v-bind:title="'Data completamento'"
                v-on:close="$emit('close')"
            />

            <v-container>
                <v-row dense>
                    <v-col cols="7">
                        <v-dialog
                            v-model="completionDate_dialog"
                            max-width="300"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="completionDate_formatted"
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="completionDateErrors"
                                    label="Data *"
                                    readonly
                                    required
                                    @blur="$v.completionDate.$touch()"
                                    @input="$v.completionDate.$touch()"

                                    prepend-inner-icon="mdi-calendar"
                                    @click:prepend-inner="completionDate_dialog = true"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="completionDate"
                                :first-day-of-week="1"
                                :show-current="$options.datePickerCurrent"
                                :min="startDate"
                                full-width
                                scrollable
                                @input="completionDate_dialog = false"
                                :title-date-format="datePickerTitleDateFormat"
                            />
                        </v-dialog>
                    </v-col>
                    <v-col cols="5">
                        <v-dialog
                            v-model="completionDateTime_dialog"
                            max-width="300"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="completionDateTime"
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    :error-messages="completionDateTimeErrors"
                                    label="Ora"
                                    readonly
                                    @blur="$v.completionDateTime.$touch()"
                                    @input="$v.completionDateTime.$touch()"
                                    @click="completionDateTime_dialog = true"

                                    prepend-inner-icon="mdi-clock-outline"
                                    @click:prepend-inner="completionDateTime_dialog = true"
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-model="completionDateTime"
                                format="24hr"
                                full-width
                                @input="completionDateTime_dialog = false"
                            />
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-container>

            <v-divider/>

            <v-card-actions>
                <v-btn
                    v-if="task.status === 1"
                    color="purple"
                    text
                    v-on:click="open"
                >
                    Riapri
                </v-btn>

                <v-spacer/>

                <v-btn
                    color="red"
                    text
                    v-on:click="confirm"
                >
                    Conferma
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {DateTime} from 'luxon'
import {mapActions} from 'vuex'
import {cloneDeep} from 'lodash'

import StandardAppBar from '../../Partials/Dialog/StandardAppBar'

export default {
    name: "Task-Dialog-Complete",

    props: [
        'id'
    ],

    mixins: [
        validationMixin
    ],

    beforeMount() {
        this.getTask(this.id).then(collection => this.task = collection);
    },

    components: {
        StandardAppBar,
    },

    datePickerCurrent: DateTime.now().toISODate(),

    data() {
        return {
            completionDate_dialog: false,
            completionDateTime_dialog: false,

            completionDate: null,
            completionDateTime: null,

            task: null,

            startDate: null,
        }
    },

    computed: {
        completionDate_formatted() {
            if (this.completionDate !== null) {
                return DateTime.fromISO(this.completionDate).toFormat('dd LLLL yyyy')
            } else {
                return null
            }
        },

        completionDateErrors() {
            const errors = []

            if (!this.$v.completionDate.$dirty) {
                return errors
            }

            !this.$v.completionDate.required && errors.push('Questo campo è obbligatorio')

            return errors
        },
        completionDateTimeErrors() {
            const errors = []

            if (!this.$v.completionDateTime.$dirty) {
                return errors
            }

            return errors
        },
    },

    watch: {
        task(collection) {
            if (collection !== null) {
                this.startDate = DateTime.fromSeconds(collection.startDate).toISODate()

                if (collection.completionDate === null) {
                    this.completionDate = null
                    this.completionDateTime = null
                } else {
                    const completionDate = DateTime.fromSeconds(collection.completionDate)
                    this.completionDate = completionDate.toISODate()
                    this.completionDateTime = completionDate.toFormat('HH:mm')
                }
            }
        }
    },

    methods: {
        ...mapActions({
            getTask: 'tasks/getTask',
            taskPut: 'tasks/put',
        }),

        /**
         * @param {string} date
         * @returns {string}
         */
        datePickerTitleDateFormat(date) {
            return DateTime.fromISO(date).toFormat('dd LLLL')
        },

        confirm() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                const recordset = cloneDeep(this.task)

                if (this.completionDate !== null) {
                    const completionDateTime = this.completionDateTime || '00:00'

                    recordset.completionDate = DateTime.fromFormat(`${this.completionDate} ${completionDateTime}`, 'yyyy-LL-dd HH:mm').toSeconds()
                    recordset.status = 1
                } else {
                    recordset.completionDate = null
                    recordset.status = 0
                }

                this.taskPut({id: this.id, recordset})
                    .finally(() => this.$emit('close'))
            }
        },

        open() {
            const recordset = cloneDeep(this.task)
            recordset.completionDate = null
            recordset.status = 0

            this.taskPut({id: this.id, recordset})
                .finally(() => this.$emit('close'))
        },
    },

    validations: {
        completionDate: {
            required,
        },
        completionDateTime: {},
    },
}
</script>

<style scoped>

</style>
