<template>
    <v-container>
        <v-row v-if="getActivitiesProxy.length">
            <template v-for="(activity, i) in getActivitiesProxyPaginated">
                <v-col
                    v-if="activity.customer"
                    :key="i"
                    cols="12"
                    md="6"
                >
                    <v-card @click.stop="dialogCardId = activity.id">
                        <v-card-text style="padding-bottom: 0">
                            <div>{{ activity.typologyName }}</div>
                            <h2 class="text-truncate" style="color: black">
                                <v-icon
                                    color="blue"
                                    @click.stop="dialogCustomerId = activity.customer.id"
                                    v-text="'mdi-open-in-new'"
                                />

                                {{ activity.customer.ragioneSociale }}
                            </h2>

                            <div style="margin: 5px 0 10px">
                                <v-chip
                                    color="red lighten-2"
                                    dark
                                    label
                                    small
                                >
                                    <v-icon
                                        left
                                        small
                                        v-text="'mdi-calendar'"
                                    />

                                    {{ activity.dateAt | dateVerbose }}
                                </v-chip>

                                <v-chip
                                    v-if="(activity.elapsedTime !== undefined) && (activity.elapsedTime !== '00:00')"
                                    color="red lighten-2"
                                    dark
                                    label
                                    small
                                    style="margin-left: 10px"
                                >
                                    <v-icon
                                        left
                                        small
                                        v-text="'mdi-timer-outline'"
                                    />

                                    {{ activity.elapsedTime }}
                                </v-chip>

                                <v-chip
                                    v-if="(activity.kmPercorsi !== null) && (activity.kmPercorsi > 0)"
                                    color="red lighten-2"
                                    dark
                                    label
                                    small
                                    style="margin-left: 10px"
                                >
                                    <v-icon
                                        left
                                        small
                                        v-text="'mdi-map-marker-distance'"
                                    />

                                    {{ activity.kmPercorsi | km }}
                                </v-chip>
                            </div>

                            <div
                                :class="'text--primary multiline-ellipsis ' + ($vuetify.breakpoint.mdAndDown ? 'fluid' : 'box')"
                                v-html="activity.description"
                            />
                        </v-card-text>

                        <v-card-actions style="padding-left: 15px">
                            <v-spacer/>

                            <ActivitiesMenu
                                :activity="activity"
                            />
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
        </v-row>

        <GlobalPlaceholderNoContents
            v-else
            v-bind:is-search="filterSearchActive"
            v-bind:icon-no-search="'mdiForumOutline'"
            v-bind:text-no-search="'Non hai ancora attività'"
        />

        <right-navigation v-model="filterNavigationDrawer">
            <template v-slot:toolbar>
                <v-btn
                    icon
                    @click="create"
                >
                    <v-icon>{{ 'mdiPlus' | mdi }}</v-icon>
                </v-btn>

                <v-btn
                    :disabled="!appOnline"
                    icon
                    @click="refresh()"
                >
                    <v-icon>{{ 'mdiRefresh' | mdi }}</v-icon>
                </v-btn>
            </template>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model.trim="filterSearch"
                        clearable
                        hide-details
                        label="Cerca"
                        outlined
                    />
                </v-col>

                <v-col cols="12">
                    <v-combobox
                        v-model="filterTags"
                        :items="tags"
                        clearable
                        hide-details
                        label="Tags"
                        multiple
                        outlined
                        small-chips
                    />
                </v-col>

                <v-col cols="12">
                    <v-combobox
                        v-show="filterCustomers_show"
                        v-model="filterCustomers"
                        :items="getAvailableCustomers"
                        clearable
                        hide-details
                        label="Clienti e prospect"
                        multiple
                        outlined
                        small-chips
                    />
                </v-col>
            </v-row>
        </right-navigation>

        <portal to="commands">
            <v-btn
                @click="filterNavigationDrawer = !filterNavigationDrawer"
                icon
            >
                <v-icon>{{ 'mdiDotsVertical' | mdi }}</v-icon>
            </v-btn>
        </portal>

        <GlobalBtnPagination
            v-bind:pagination="pagination"
            v-bind:paginationLoading="paginationLoading"
            v-on:click="paginationOffset += paginationLimit"
            v-intersect="onIntersect"
        />

        <ActivitiesDialogCard
            v-if="dialogCard"
            v-bind:id="dialogCardId"
            v-on:close="dialogCard = false"
        />

        <GlobalBtnFabToTop/>

        <RegistriesDialogCard
            v-if="dialogCustomer"
            v-bind:id="dialogCustomerId"
            v-on:close="dialogCustomer = false"
        />
    </v-container>
</template>

<script>
import { capitalize, filter, includes, orderBy, reduce, slice, throttle, uniqBy } from 'lodash';
import { mapGetters, mapState } from 'vuex'
import { DateTime } from 'luxon'
import Activities from "../models/Activities"
import ActivitiesDialogCard from '../components/Activities/Dialog/Card'
import ActivitiesMenu from '../components/Activities/Menu'
import GlobalBtnFabToTop from '../components/Global/Btn/FabToTop'
import GlobalBtnPagination from '../components/Global/Btn/Pagination'
import GlobalPlaceholderNoContents from '../components/Global/Placeholder/NoContents'
import RegistriesDialogCard from '../components/Registries/Dialog/Card'
import RightNavigation from "../components/RightNavigation.vue";
import { Portal } from "portal-vue";
import { settings } from "../libraries/storage";

export default {
    beforeMount() {
        if (this.$route.params.customer) {
            this.filterCustomers_show = false
            this.$store.commit('setToolbarTitle', 'Attività cliente')
        } else {
            this.$store.commit('setToolbarTitle', 'Attività')
        }

        if (settings.has('activities', 'filters', 'customers')) {
            this.filterCustomers = settings.get('activities', 'filters', 'customers')
        }

        if (settings.has('activities', 'filters', 'search')) {
            this.filterSearch = settings.get('activities', 'filters', 'search')
        }

        if (settings.has('activities', 'filters', 'tags')) {
            this.filterTags = settings.get('activities', 'filters', 'tags')
        }
    },

    beforeRouteEnter(to, from, next) {
        if (!settings.has('sync', 'activities', 'offset_date')) {
            next({
                path: '/sync',
                query: {
                    redirectTo: to.fullPath,
                },
            })
        } else {
            next()
        }
    },

    components: {
        ActivitiesDialogCard,
        ActivitiesMenu,
        GlobalBtnFabToTop,
        GlobalBtnPagination,
        GlobalPlaceholderNoContents,
        Portal,
        RegistriesDialogCard,
        RightNavigation,
    },

    computed: {
        ...mapGetters({
            getAvailableCustomers: 'activities/getAvailableCustomers',
            getVerboseCollection: 'activities/getVerboseCollection',
        }),
        ...mapState({
            appOnline: state => state._default.appOnline,
        }),

        filterSearchActive() {
            return (
                (this.filterCustomers.length > 0) ||
                ((this.filterSearch !== null) && (this.filterSearch.length > 2)) ||
                (this.filterTags.length > 0)
            )
        },

        getActivitiesProxy() {
            let activities = this.getVerboseCollection

            if (this.$route.params.customer !== undefined) {
                let customer = this.$route.params.customer

                if (!isNaN(customer)) {
                    customer = parseInt(customer)
                }

                activities = filter(activities, a => {
                    if (typeof a.customer === 'object') {
                        return a.customer.id === customer;
                    } else {
                        return a.customer === customer
                    }
                })
            }

            if (this.getFilterCustomers.length) {
                activities = filter(activities, a => includes(this.getFilterCustomers, a.customer))
            }

            if (this.getFilterTags.length) {
                activities = filter(activities, a => {
                    if (a.tags.length) {
                        return reduce(a.tags, (test, t) => {
                            if (test === false) {
                                test = includes(this.getFilterTags, t.tag)
                            }

                            return test
                        }, false)
                    } else {
                        return false
                    }
                })
            }

            if ((this.filterSearch !== null) && (this.filterSearch.length > 2)) {
                activities = filter(activities, a => {
                    const regex = new RegExp(this.filterSearch, 'is')
                    const test = []

                    test.push(a.customer.ragioneSociale.match(regex) !== null)
                    test.push(a.description.match(regex) !== null)

                    return reduce(test, (sum, t) => {
                        if (sum === false) {
                            sum = t
                        }

                        return sum
                    }, false)
                })
            }

            return orderBy(activities, ['dateAt'], ['desc'])
        },
        getActivitiesProxyPaginated() {
            let collection = this.getActivitiesProxy

            collection = slice(collection, 0, this.paginationOffset + this.paginationLimit)

            return collection
        },

        getFilterCustomers() {
            return reduce(this.filterCustomers, (filter, c) => {
                filter.push(c.value)

                return filter
            }, [])
        },

        getFilterTags() {
            return reduce(this.filterTags, (filter, t) => {
                filter.push(t.value)

                return filter
            }, [])
        },

        page() {
            return (this.paginationOffset / this.paginationLimit) + 1
        },
        pages() {
            return Math.ceil(this.getActivitiesProxy.length / this.paginationLimit)
        },
        pagination() {
            return this.page < this.pages
        },

        tags() {
            const tags = reduce(this.activities, (tags, a) => {
                if (a.tags !== undefined) {
                    a.tags.map(t => {
                        tags.push({
                            text: capitalize(t.tag),
                            value: t.tag,
                        })
                    })
                }

                return tags
            }, [])

            return orderBy(uniqBy(tags, 'value'), ['text'], ['asc'])
        },
    },

    data() {
        return {
            dialogCard: false,
            dialogCardId: null,

            dialogCustomer: false,
            dialogCustomerId: null,

            filterNavigationDrawer: false,
            filterCustomers: [],
            filterCustomers_show: true,
            filterSearch: null,
            filterTags: [],

            paginationLimit: 10,
            paginationLoading: false,
            paginationOffset: 0,
        }
    },

    filters: {
        dateVerbose: (isoDate) => DateTime.fromISO(isoDate).toFormat('d LLL y'),

        km: (distance) => new Intl.NumberFormat('it', {
            style: 'unit',
            unit: 'kilometer',
        }).format(distance),

        nl2br: (text) => (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2'),
    },

    methods: {
        async create() {
            if (this.$route.params.customer === undefined) {
                await this.$router.push('/activities/create')
                    .catch(() => {
                        // I don't what I do
                    })
            } else {
                await this.$router.push(`/registries/${this.$route.params.customer}/activities/create`)
                    .catch(() => {
                        // I don't what I do
                    })
            }
        },

        onIntersect: throttle(function (entries, observer, isIntersecting) {
            if ((this.paginationOffset / this.paginationLimit) <= 4) {
                this.paginationLoading = isIntersecting

                if (isIntersecting === true) {
                    this.paginationOffset += this.paginationLimit
                }
            }
        }, 300),

        async refresh() {
            await Activities.toCloud()

            let response
            let first_result = 0

            do {
                response = await Activities.fromCloud(first_result)

                if (typeof response === "number") {
                    first_result = response
                } else if (typeof response !== "boolean") {
                    break
                }
            } while (response !== true)
        },
    },

    name: "Activities",

    watch: {
        dialogCard(status) {
            if (status === false) {
                this.dialogCardId = null
            }
        },

        dialogCardId(id) {
            if (id !== null) {
                this.dialogCard = true
            }
        },

        dialogCustomer(status) {
            if (status === false) {
                this.dialogCustomerId = null
            }
        },
        dialogCustomerId(id) {
            if (id !== null) {
                this.dialogCustomer = true
            }
        },

        filterCustomers(customers) {
            if (customers.length) {
                settings.set('activities', 'filters', 'customers', customers)
            } else {
                settings.del('activities', 'filters', 'customers')
            }
        },

        filterSearch(search) {
            if (search !== null) {
                settings.set('activities', 'filters', 'search', search)
            } else {
                settings.del('activities', 'filters', 'search')
            }
        },

        filterTags(tags) {
            if (tags.length) {
                settings.set('activities', 'filters', 'tags', tags)
            } else {
                settings.del('activities', 'filters', 'tags')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.multiline-ellipsis {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;

    &.box {
        height: 64px;
    }

    &.fluid {
        max-height: 64px;
    }
}
</style>
