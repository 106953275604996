<script>
import { deleteToken, getMessaging, isSupported, getToken } from "firebase/messaging"
import { mapMutations, mapState } from "vuex";
import { firebaseApp } from "../../libraries/firebase";
import Bugsnag from "@bugsnag/js";
import axios from "../../libraries/axios";
import { settings } from "../../libraries/storage";

export default {
    computed: {
        ...mapState({
            hasNotificationEnabled: state => state._default.hasNotificationEnabled,
        }),

        isAddedToHomeScreen() {
            if (this.isAndroid) {
                return window.navigator?.standalone || false
            }

            if (this.isIOS) {
                return window.matchMedia('(display-mode: standalone)').matches
            }

            return false
        },

        isAndroid() {
            return (navigator.platform.toLowerCase() === "android")
        },

        isIOS() {
            return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
        },

        iOSVersion() {
            const v = (/CPU.*OS ([0-9_]+) like Mac OS X/).exec(navigator.userAgent)

            if (v) {
                return parseFloat(v[ 1 ].replace(/_/g, '.'))
            }

            return 0
        },

        silent() {
            return (this.permission === "granted")
        },
    },

    async created() {
        this.hasPushSupport = await this.hasNotificationSupport()

        if (this.hasPushSupport) {
            this.permission = Notification.permission

            this.messaging = getMessaging(firebaseApp)

            if (this.hasNotificationEnabled && this.silent) {
                const token = await getToken(this.messaging)

                if (token) {
                    await this.onToken(token)
                }
            }
        }
    },

    data() {
        return {
            hasPushSupport: false,
            messaging: null,
            permission: null,
            working: false,
        }
    },

    methods: {
        ...mapMutations({
            setHasNotificationEnabled: 'setHasNotificationEnabled',
        }),

        async hasNotificationSupport() {
            let hasPushSupport = await isSupported()

            if (this.isIOS && !this.isAddedToHomeScreen && (this.iOSVersion < 16.4)) {
                hasPushSupport = false
            }

            return hasPushSupport
        },

        async onToggleStatus() {
            this.working = true

            try {
                if (this.hasPushSupport && (this.permission !== "denied") && this.messaging) {
                    if (!this.hasNotificationEnabled) {
                        const token = await getToken(this.messaging)

                        if (token) {
                            await this.onToken(token)

                            this.setHasNotificationEnabled(true)
                        }
                    } else {
                        await Promise.all([
                            this.onToken(null),
                            deleteToken(this.messaging),
                        ])

                        this.setHasNotificationEnabled(false)
                    }
                }
            } catch (reason) {
                Bugsnag.notify(reason)
                console.error(reason)
            }

            this.working = false
        },

        async onToken(token) {
            await axios({
                data: {
                    token: token || undefined,
                },
                method: token ? 'PUT' : 'DELETE',
                url: `/v2/devices/${settings.get('app', 'uuid')}`,
            })
        },
    },

    name: "PushComponent",

    props: {
        stealth: Boolean,
    },
}
</script>

<template>
    <v-list-item
        v-if="!stealth && (permission && (permission !== 'denied'))"
        two-line
    >
        <v-list-item-action>
            <v-btn
                :loading="working"
                color="primary"
                fab
                @click="onToggleStatus()"
            >
                <v-icon
                    :class="{ ringing: !hasNotificationEnabled }"
                >
                    {{ (hasNotificationEnabled ? 'mdiBellOffOutline' : 'mdiBellRingOutline') | mdi }}
                </v-icon>
            </v-btn>
        </v-list-item-action>

        <v-list-item-content>
            <v-list-item-title>
                {{ hasNotificationEnabled ? 'Notifiche abilitate' : 'Abilita le notifiche' }}
            </v-list-item-title>

            <v-list-item-subtitle class="no-ellipsis">
                <span v-if="!hasNotificationEnabled">
                    Tocca la campana per ricevere avvisi e altre comunicazioni.
                </span>
                <span v-else>
                    Tocca la campana per disattivare gli avvisi e le altre comunicazioni.
                </span>
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<style scoped>
.ringing {
    transform-origin: center top 0;
    animation: ring 2.5s ease 3;
}
</style>
