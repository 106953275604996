<script>
export default {
    created() {
        this.navigationDrawer = this.value
    },
    data() {
        return {
            navigationDrawer: false,
        }
    },
    name: "RightNavigation",
    props: {
        default: () => false,
        value: Boolean,
    },
    watch: {
        value(value) {
            this.navigationDrawer = value
        },
        navigationDrawer(navigationDrawer) {
            if (navigationDrawer) {
                this.$store.commit('setThemeColor', '~#721E1E')
            } else {
                this.$store.commit('setThemeColor', null)
            }

            this.$emit('input', navigationDrawer)
        }
    },
}
</script>

<template>
    <v-navigation-drawer
        v-model="navigationDrawer"
        :bottom="$vuetify.breakpoint.mdAndDown"
        :right="!$vuetify.breakpoint.mdAndDown"
        app
        style="max-width: 90%; height: 100dvh"
        temporary
        width="320"
    >
        <template v-slot:prepend>
            <v-toolbar
                color="secondary"
                dark
            >
                <v-btn
                    @click="navigationDrawer = false"
                    icon
                >
                    <v-icon>{{ ($vuetify.breakpoint.mdAndDown ? 'mdiChevronDown' : 'mdiClose') | mdi }}</v-icon>
                </v-btn>

                <v-spacer/>

                <slot name="toolbar"/>
            </v-toolbar>
        </template>

        <v-container class="px-4 py-6">
            <slot/>
        </v-container>
    </v-navigation-drawer>
</template>

<style scoped>

</style>
