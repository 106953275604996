<template>
    <v-dialog
        v-if="data !== null"
        :fullscreen="fullscreen"
        :transition="transition"
        :value="true"
        max-width="600"
        overlay-color="grey darken-4"
        persistent
        scrollable
    >
        <v-card :tile="fullscreen">
            <v-toolbar
                class="flex-grow-0"
                color="secondary"
                dark
            >
                <v-toolbar-title
                    v-html="data.typologyName"
                />

                <v-spacer/>

                <ActivitiesMenu
                    v-bind:activity="data"
                />

                <v-btn
                    icon
                    @click="$emit('close')"
                >
                    <v-icon
                        dark
                        v-text="'mdi-close'"
                    />
                </v-btn>
            </v-toolbar>

            <v-container>
                <div class="mt-1 mb-2">
                    <v-chip
                        :color="$options.red.lighten2"
                        dark
                        label
                        small
                    >
                        <v-icon
                            left
                            small
                            v-text="'mdi-calendar'"
                        />

                        {{ data.dateAt | dateVerbose }}
                    </v-chip>

                    <v-chip
                        v-if="data.elapsedTime && (data.elapsedTime !== '00:00')"
                        :color="$options.red.lighten2"
                        class="ml-2"
                        dark
                        label
                        small
                    >
                        <v-icon
                            left
                            small
                        >
                            mdi-timer-outline
                        </v-icon>

                        {{ data.elapsedTime }}
                    </v-chip>

                    <v-chip
                        v-if="(data.kmPercorsi !== null) && (data.kmPercorsi > 0)"
                        :color="$options.red.lighten2"
                        dark label small
                        style="margin-left: 10px"
                    >
                        <v-icon
                            left small
                            v-text="'mdi-map-marker-distance'"
                        />

                        {{ data.kmPercorsi | km }}
                    </v-chip>
                </div>

                <div
                    v-if="data.tags.length"
                    style="margin: 5px 0 10px"
                >
                    <v-chip
                        v-for="(t, i) in data.tags"
                        :key="i"
                        color="light-blue"
                        :style="(i === 0) ? '' : 'margin-left: 10px'"
                        dark label small
                    >
                        <v-icon
                            left small
                            v-text="'mdi-tag-outline'"
                        />

                        {{ t.tag }}
                    </v-chip>
                </div>

                <h3
                    v-html="data.customer.isProspect ? 'Cliente prospect' : 'Cliente'"
                />
                <p
                    class="body-2"
                    v-html="data.customer.ragioneSociale"
                />

                <h3>Descrizione</h3>
                <p
                    class="body-2"
                    v-html="$options.filters.nl2br(data.description)"
                />

                <div v-if="data.attachments !== undefined && data.attachments.length">
                    <h3>Allegati</h3>
                    <v-list>
                        <v-list-item
                            v-for="attach in data.attachments"
                            :key="attach.id"
                        >
                            <v-list-item-icon>
                                <v-icon>
                                    {{ attach.file.extension | icon }}
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title
                                    v-html="attach.file.caption"
                                />
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn
                                    icon
                                    @click="open(attach.file)"
                                >
                                    <v-icon
                                        color="blue"
                                        v-text="'mdi-open-in-new'"
                                    />
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import {mapActions} from 'vuex'

import ActivitiesMenu from '../Menu'
import { settings } from "../../../libraries/storage";
import { DateTime } from "luxon";

export default {
    beforeMount() {
        this.getActivityVerbose(this.id)
            .then(activity => this.data = activity)
    },

    components: {
        ActivitiesMenu,
    },

    computed: {
        fullscreen() {
            return (this.$vuetify.breakpoint.width < 600)
        },

        transition() {
            return this.fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'
        },
    },

    data() {
        return {
            data: null,
        }
    },

    filters: {
        dateVerbose: (isoDate) => DateTime.fromISO(isoDate).toFormat('d LLL y'),

        icon(extension) {
            const associations = {
                jpg: 'mdi-image-outline',
                jpeg: 'mdi-image-outline',
                png: 'mdi-image-outline',
                pdf: 'mdi-file-pdf-outline',
                txt: 'mdi-file-document-outline',
                doc: 'mdi-file-word-outline',
                docx: 'mdi-file-word-outline',
                xls: 'mdi-file-excel-outline',
                xlsx: 'mdi-file-excel-outline',
            }

            return associations[ extension ] || 'mdi-file-outline'
        },

        km: (distance) => new Intl.NumberFormat('it', {
            style: 'unit',
            unit: 'kilometer',
        }).format(distance),

        nl2br: (text) => (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2'),
    },

    methods: {
        ...mapActions({
            getActivityVerbose: 'activities/getActivityVerbose',
        }),

        /**
         * @param {Object} file
         */
        open(file) {
            if (file.uri !== false) {
                const q = new URLSearchParams({
                    s: settings.get('session'),
                    xrb: settings.get('app', 'uuid'),
                })

                window.open(file.uri + `?${q.toString()}`, '_blank')
            } else if (file.base64 !== undefined) {
                if ((file.extension === 'jpg') ||
                    (file.extension === 'jpeg') ||
                    (file.extension === 'png')
                ) {
                    const image = new Image()
                    image.src = file.dataUrlPrologue + '' + file.base64

                    const w = window.open('')
                    w.document.write(image.outerHTML)
                }
            }
        },

        resize(loop = 0) {
            const header = this.$refs[ 'app-bar' ].$el
            const card = this.$refs[ 'app-bar' ].$parent.$el

            if (card.offsetWidth !== 0) {
                header.style.width = card.offsetWidth + 'px'
            } else {
                if (loop <= 100) {
                    setTimeout(() => this.resize(++loop), 50)
                }
            }
        },
    },

    name: "Activities-Dialog-Card",

    props: [
        'id',
    ],

    red: colors.red,
}
</script>

<style scoped>

</style>
