<template>
    <div>
        <v-subheader
            class="text-uppercase font-weight-bold"
            inset
        >
            Ultima sincronizzazione
        </v-subheader>

        <v-row no-gutters>
            <v-col
                v-for="(catalog, index) in catalogs"
                :key="index"
                cols="12"
                md="6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>{{ 'mdiDatabaseClockOutline' | mdi }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title
                            v-html="catalog.title"
                        />
                        <v-list-item-subtitle>
                            {{catalog.offsetDate }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { settings } from "../../libraries/storage";
import { map } from "lodash";

export default {


    computed: {
        catalogs() {
            const catalogs = [
                {
                    context: 'registries',
                    title: 'Anagrafiche',
                },
                {
                    context: 'registries/assets',
                    title: 'Anagrafiche <small>(<i>Apparati</i>)</small>',
                },
                {
                    context: 'registries/details',
                    title: 'Anagrafiche <small>(<i>Dettagli</i>)</small>',
                },
                {
                    context: 'registries/linee',
                    title: 'Anagrafiche <small>(<i>Linee</i>)</small>',
                },
                {
                    context: 'registries/tags',
                    title: 'Anagrafiche <small>(<i>Tags</i>)</small>',
                },

                {
                    context: 'activities',
                    title: 'Attività',
                },
                {
                    context: 'activities/tags',
                    title: 'Attività <small>(<i>Tags</i>)</small>',
                },
                {
                    context: 'activities/typologies',
                    title: 'Attività <small>(<i>Tipologie</i>)</small>',
                },

                {
                    context: 'tasks',
                    title: 'Cose da fare',
                },

                {
                    context: 'linklania',
                    title: 'Linklania',
                },
            ]

            return map(catalogs, (item) => {
                item.offsetDate = this.$options.filters.dateVerbose(settings.get('sync', item.context, 'offset_date', 0))

                return item
            })
        }
    },

    methods: {
        getOffset(context) {
            return settings.get('sync', context, 'offset_date')
        },
    },

    name: "About-Database",
}
</script>
