<template>
    <v-toolbar
        class="flex-grow-0"
        color="secondary"
        dark
    >
        <v-toolbar-title class="subtitle-1">
            {{ title }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn
            icon
            @click="$emit('close')"
        >
            <v-icon dark>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
export default {
    name: "Partials-Dialog-StandardAppBar",

    props: {
        title: {
            required: true,
            type: String,
        }
    },
}
</script>
