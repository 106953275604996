<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="fullscreen"
        :transition="transition"
        max-width="450"
        persistent
        scrollable
    >
        <template v-slot:activator="{ attrs, on }">
            <v-list-item
                v-bind="attrs"
                v-on="on"
            >
                <v-list-item-icon>
                    <v-icon color="red">
                        mdi-delete-outline
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ actionLabel }}
                </v-list-item-title>
            </v-list-item>
        </template>

        <v-card :tile="fullscreen">
            <StandardAppBar
                :title="title"
                @close="onKo()"
            />

            <v-card-text>
                <v-container class="text">
                    {{ question }}
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    :color="koColor"
                    text
                    @click="onKo()"
                >
                    {{ koLabel }}
                </v-btn>

                <v-spacer/>

                <v-btn
                    :color="okColor"
                    text
                    @click="onOk()"
                >
                    {{ okLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import StandardAppBar from '../../Partials/Dialog/StandardAppBar'

export default {
    components: {
        StandardAppBar
    },

    computed: {
        fullscreen() {
            return (this.$vuetify.breakpoint.width < 600)
        },
        transition() {
            return this.fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'
        },
    },

    data() {
        return {
            dialog: false
        }
    },

    methods: {
        onKo() {
            this.dialog = false
            this.$emit('ko-action')
        },
        onOk() {
            this.dialog = false
            this.$emit('ok-action')
        },
    },

    name: "Global-Dialog-Confirm",

    props: {
        actionLabel: {
            default: 'Cancella',
            type: String,
        },
        koColor: {
            default: 'green',
            type: String,
        },
        koLabel: {
            default: 'Annulla',
            type: String,
        },
        okColor: {
            default: 'red',
            type: String,
        },
        okLabel: {
            default: 'OK',
            type: String,
        },
        question: {
            required: true,
            type: String,
        },
        title: {
            required: true,
            type: String,
        },
    },
}
</script>
