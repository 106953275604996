<template>
    <div>
        <v-subheader
            class="text-uppercase font-weight-bold"
            inset
        >
            Legale
        </v-subheader>

        <v-row no-gutters>
            <v-col
                cols="12"
                md="6"
            >
                <v-dialog
                    v-model="dialog"
                    max-width="600"
                    persistent
                    scrollable
                    :fullscreen="fullscreen"
                    :transition="transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ 'mdiFormatAlignLeft' | mdi }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    Licenze
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Licenze open source utilizzate nel software.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-card
                        style="position: relative"
                    >
                        <StandardAppBar
                            :icon="'mdiFormatAlignLeft'"
                            :title="'Licenze open source'"
                            @close="dialog = false"
                        />

                        <v-card-text id="scroll" class="pa-2 pb-0 overflow-y-auto">
                            <v-container
                                class="pb-0 text-body-2 grey--text text--darken-3 text-pre-wrap"
                                style="font-size: 10px; line-height: 1.1em"
                                v-html="$options.licenses"
                            />
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from "../../libraries/axios";

import StandardAppBar from '../../components/Partials/Dialog/StandardAppBar'
import colors from "vuetify/lib/util/colors";

export default {
    name: "About-Legale",

    components: {
        StandardAppBar,
    },

    licenses: null,

    created() {
        if (this.$options.licenses === null) {
            axios.get('/3rdpartylicenses.txt', {baseURL: '/'}).then(res => this.$options.licenses = res.data);
        }
    },

    data() {
        return {
            dialog: false,
        }
    },

    computed: {
        fullscreen() {
            return (this.$vuetify.breakpoint.width < 600)
        },
        transition() {
            return this.fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'
        },
    },

    watch: {
        dialog(dialog) {
            if (dialog && this.fullscreen) {
                this.$store.commit('setThemeColor', colors.red.darken1)
            } else {
                this.$store.commit('setThemeColor', null)
            }
        }
    }
}
</script>
