<template>
    <v-container
        class="fill-height"
        fluid
    >
        <v-card
            v-if="showForm"
            class="mx-auto"
            flat
            max-width="300"
            width="100%"
        >
            <v-card-text>
                <v-img
                    class="mx-auto"
                    src="/images/maskable_icon_x384.png"
                    width="160"
                />

                <form>
                    <v-text-field
                        v-model="usernm"
                        color="primary"
                        label="Username"
                        required

                        :error-messages="usernmErrors"
                        @blur="$v.usernm.$touch()"
                        @input="$v.usernm.$touch()"
                    />

                    <v-text-field
                        v-model="passwd"
                        :type="showPasswd ? 'text' : 'password'"
                        color="primary"
                        label="Password"
                        autocomplete
                        required

                        :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPasswd = !showPasswd"

                        :error-messages="passwdErrors"
                        @blur="$v.passwd.$touch()"
                        @input="$v.passwd.$touch()"
                    />

                    <small
                        v-if="isBeta"
                        class="float-left"
                        style="margin-top: 6px"
                        v-html="$options.version"
                    />

                    <v-btn
                        @click="enter"
                        class="float-right"
                        icon
                    >
                        <v-icon
                            color="primary"
                            right
                        >
                            mdi-location-enter
                        </v-icon>
                    </v-btn>
                </form>
            </v-card-text>
        </v-card>

        <v-snackbar
            v-model="snackbar"
            timeout="2500"
            color="secondary"
        >
            <div
                class="text-center"
                v-html="snackbarContent"
            />
        </v-snackbar>
    </v-container>
</template>

<script>
import { version } from '../../package.json'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import axios from "../libraries/axios"
import jwt from 'jsonwebtoken'
import * as base64 from 'base-64'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from "luxon"
import { mapActions, mapState } from 'vuex'
import { homepage } from "../plugins/router"
import { settings } from "../libraries/storage";

export default {
    name: 'Login',
    mixins: [validationMixin],

    beforeMount() {
        this.$store.commit('setToolbarTitle', 'Login')

        if (!settings.has('app', 'uuid')) {
            settings.set('app', 'uuid', null, uuidv4())
        }


        if (settings.has('login', 'bearer')) {
            this.showForm = false

            this.__login(settings.get('login', 'bearer'))
        }

        if (settings.has('app', 'start-up')) {
            const {startUp} = this.remoteConfig
            const lastSync = DateTime.fromMillis(settings.get('app', 'start-up'))
            const now = DateTime.now()
            const diff = now.diff(lastSync, ['milliseconds']).toObject()

            if (diff.milliseconds > startUp) {
                this.landingPage = '/sync'
            } else {
                this.landingPage = homepage
            }
        }
    },

    version,

    data() {
        return {
            showForm: true,

            usernm: '',
            passwd: '',
            autologin: false,
            showPasswd: false,

            snackbar: false,
            snackbarContent: null,

            landingPage: '/sync',
        }
    },

    computed: {
        ...mapState({
            remoteConfig: state => state._default.remoteConfig,
        }),

        usernmErrors() {
            const errors = []

            if (!this.$v.usernm.$dirty) {
                return errors;
            }

            !this.$v.usernm.required && errors.push('Campo obbligatorio')

            return errors
        },
        passwdErrors() {
            const errors = []

            if (!this.$v.passwd.$dirty) {
                return errors;
            }

            !this.$v.passwd.required && errors.push('Campo obbligatorio')

            return errors
        },

        authorization() {
            const token = JSON.stringify({
                username: this.usernm,
                password: this.passwd,
            })

            return base64.encode(token)
        },

        isBeta() {
            return (process.env.VUE_APP_BETA === "1");
        }
    },

    watch: {
        snackbar(status) {
            if (status === false) {
                this.snackbarContent = null
            }
        },
        snackbarContent(content) {
            if (content !== null) {
                this.snackbar = true
            }
        }
    },

    methods: {
        ...mapActions({
            setUser: 'user/setUser',
        }),

        enter() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.__login(this.authorization)
            }
        },

        __login(token) {
            const uuid = settings.get('app', 'uuid')

            axios.get('/v2/login', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Requested-By': uuid,
                },
            }).then(({data, status}) => {
                if (status === 200) {
                    const {returnCode, returnText, returnData} = data

                    if (returnCode === 218) {
                        const {payload, signature} = returnData
                        const secret = uuid.split('').reverse().join('')

                        jwt.verify(signature, secret, {
                            algorithms: ['HS256'],
                            // issuer: (new URL(process.env.VUE_APP_API)).hostname,
                            subject: uuid
                        }, async (err) => {
                            if (err) {
                                this.showForm = true
                                this.snackbarContent = "I dati autoritativi non sono corretti. Contatta l'amministrazione."

                                settings.del('login', 'bearer')
                            } else {
                                settings.set('login', 'bearer', null, token)
                                settings.set('session', null, null, signature)
                                await this.setUser(payload)

                                this.usernm = ''
                                this.passwd = ''
                                this.autologin = false
                                this.showPasswd = false

                                if (this.$route.query.redirectTo) {
                                    await this.$router.push(this.$route.query.redirectTo)
                                        .catch(() => {
                                            // I know what I do!
                                        })
                                } else {
                                    await this.$router.push(this.landingPage)
                                        .catch(() => {
                                            // I know what I do!
                                        })
                                }
                            }
                        })
                    } else {
                        this.showForm = true

                        if (returnCode === 401) {
                            this.snackbarContent = "I dati di accesso non sono validi."
                        } else if (returnCode === 403) {
                            this.snackbarContent = "Non sei autorizzato ad accedere. Contatta l'amministrazione."
                        } else if (returnCode === 412) {
                            this.snackbarContent = "Inserisci username e password."
                        } else {
                            this.snackbarContent = returnText
                        }

                        settings.del('login', 'bearer')
                    }
                }
            }).catch(() => this.showForm = true)
        },
    },

    validations: {
        usernm: {
            maxLength: maxLength(255),
            required,
        },
        passwd: {
            maxLength: maxLength(255),
            required,
        }
    },
}
</script>
