<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="fullscreen"
        :transition="transition"
        max-width="850"
        persistent
        scrollable
    >
        <template v-slot:activator="{ attrs, on }">
            <v-list-item
                v-bind="attrs"
                v-on="on"
            >
                <v-list-item-icon>
                    <v-icon color="green">
                        mdi-attachment
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                    Allegati
                </v-list-item-title>
            </v-list-item>
        </template>

        <v-card :tile="fullscreen">
            <StandardAppBar
                title="Allegati"
                @close="dialog = false"
            />

            <v-container>
                <v-file-input
                    v-model="inputs"
                    :accept="getGeneralAccept"
                    :append-outer-icon="(inputs.length > 0) ? 'mdi-content-save-outline' : null"
                    @click:append-outer="attach"
                    multiple
                    show-size
                    small-chips
                    truncate-length="15"
                />

                <v-list dense>
                    <v-list-item
                        v-for="attach in getAttachments"
                        :key="attach.id"
                        dense
                    >
                        <v-list-item-content>
                            <v-text-field
                                :id="`caption-${attach.id}`"
                                :ref="`ref-caption-${attach.id}`"
                                :prepend-icon="$options.filters.icon(attach.file.extension)"
                                :value="attach.file.caption"
                                dense
                                @blur="update"
                            />
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-menu
                                bottom
                                offset-y
                                origin="top left"
                                transition="scale-transition"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item
                                        @click="open(attach)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="black">mdi-open-in-new</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Apri
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-divider/>

                                    <global-dialog-confirm
                                        ok-label="Conferma"
                                        question="Sei sicuro di voler cancellare l'allegato? La cancellazione è irreversibile e si perderanno tutti i dati."
                                        title="Conferma cancellazione"
                                        @ok-action="trash(attach.id)"
                                    />
                                </v-list>
                            </v-menu>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { filter, find } from 'lodash'
import { mapActions } from 'vuex'
import { pathinfo } from '../../../../libraries/php'
import GlobalDialogConfirm from '../Confirm'
import StandardAppBar from '../../../Partials/Dialog/StandardAppBar'
import { settings } from "../../../../libraries/storage";

export default {
    components: {
        GlobalDialogConfirm,
        StandardAppBar,
    },

    computed: {
        fullscreen() {
            return (this.$vuetify.breakpoint.width < 600)
        },

        getAttachments() {
            return filter(this.attachments, a => a.file !== false)
        },

        getGeneralAccept() {
            const mimes = [
                'image/jpeg',
                'image/png',
                'application/pdf',
                'application/x-pdf',
                'text/plain',
                'application/msword',
                'application/vnd.ms-excel',
                '.dwg',
                '.stp',
            ]

            return mimes.join(',')
        },

        transition() {
            return this.fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'
        },
    },

    created() {
        this.load()
    },

    data() {
        return {
            dialog: false,
            dialogDelete: false,
            dialogDeleteId: null,
            attachments: [],
            inputs: [],
        }
    },

    filters: {
        /**
         * @param {string} dataUrl
         * @returns {string}
         */
        base64(dataUrl) {
            return dataUrl.replace('data:', '').replace(/^.+,/, '')
        },

        /**
         * @param {string} extension
         * @returns {string}
         */
        icon(extension) {
            const associations = {
                jpg: 'mdi-image-outline',
                jpeg: 'mdi-image-outline',
                png: 'mdi-image-outline',
                pdf: 'mdi-file-pdf-outline',
                txt: 'mdi-file-document-outline',
                doc: 'mdi-file-word-outline',
                docx: 'mdi-file-word-outline',
                xls: 'mdi-file-excel-outline',
                xlsx: 'mdi-file-excel-outline',
            }

            return associations[extension] || 'mdi-file-outline'
        },
    },

    methods: {
        ...mapActions({
            getActivity: `activities/getActivity`,
            activitiesAttachmentsAdd: `activities/attachmentsAdd`,
            activitiesAttachmentTrash: `activities/attachmentTrash`,
            activitiesAttachmentUpdate: `activities/attachmentUpdate`,

            getRegistry: `registries/getRegistry`,
            registriesAttachmentsAdd: `registries/attachmentsAdd`,
            registriesAttachmentTrash: `registries/attachmentTrash`,
            registriesAttachmentUpdate: `registries/attachmentUpdate`,
        }),

        async attach() {
            const attachments = [];

            for (let i = 0, ii = this.inputs.length; i !== ii; i++) {
                attachments.push(await this.__prepareAttach(this.inputs[i]))
            }

            const payload = {
                id: this.id,
                attachments,
            }

            let action

            if (this.context === 'activities') {
                action = this.activitiesAttachmentsAdd(payload)
            } else if (this.context === 'registries') {
                action = this.registriesAttachmentsAdd(payload)
            }

            action.then(() => this.load())
            action.then(() => this.inputs = [])

            this.inputs = []
        },

        load() {
            if (this.context === 'activities') {
                this.getActivity(this.id)
                    .then(activity => this.attachments = activity.attachments)
            } else if (this.context === 'registries') {
                this.getRegistry(this.id)
                    .then(registry => this.attachments = registry.attachments)
            }
        },

        /**
         * @param {Object} file
         */
        open({file}) {
            if (file.uri !== false) {
                const q = new URLSearchParams({
                    s: settings.get('session'),
                    xrb: settings.get('app', 'uuid'),
                })

                window.open(file.uri + `?${q.toString()}`, '_blank')
            } else if (file.base64 !== undefined) {
                if (file.extension === 'jpg' || file.extension === 'jpeg' || file.extension === 'png') {
                    const image = new Image()
                    image.src = file.dataUrlPrologue + '' + file.base64

                    const w = window.open('')
                    w.document.write(image.outerHTML)
                }
            }
        },

        /**
         * @param {Number|String} attachmentId
         */
        trash(attachmentId) {
            const payload = {
                id: this.id,
                attachmentId,
            }

            let action

            if (this.context === 'activities') {
                action = this.activitiesAttachmentTrash(payload)
            } else if (this.context === 'registries') {
                action = this.registriesAttachmentTrash(payload)
            }

            action.then(() => this.load())
            action.finally(() => this.dialogDelete = false)
        },

        /**
         * @param {Event} e
         */
        update(e) {
            const input = e.target
            const caption = input.value.substr(0, 45).trim()

            let id = input.id.replace('caption-', '').trim()

            if (!isNaN(id)) {
                id = parseInt(id)
            }

            const attachment = find(this.attachments, a => a.id === id)

            if (attachment.file.caption !== caption) {
                this.$refs[`ref-${input.id}`][0].loading = true

                attachment.file.caption = caption

                const payload = {
                    id: this.id,
                    attachment,
                }

                let action

                if (this.context === 'activities') {
                    action = this.activitiesAttachmentUpdate(payload)
                } else if (this.context === 'registries') {
                    action = this.registriesAttachmentUpdate(payload)
                }

                action.then(() => this.load())
                action.finally(() => this.$refs[`ref-${input.id}`][0].loading = false)
            }
        },

        /**
         * @param {File} file
         * @returns {Promise<Object>}
         * @private
         */
        __prepareAttach(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener('load', e => {
                    const fileinfo = pathinfo(file.name)

                    resolve({
                        id: uuidv4(),
                        file: {
                            caption: fileinfo.filename.substr(0, 45).trim(),
                            exists: true,
                            extension: fileinfo.extension,
                            filename: file.name,
                            uri: false,
                            base64: this.$options.filters.base64(e.target.result),
                            dataUrlPrologue: `data:${file.type};base64,`,
                        }
                    })
                })
                reader.addEventListener('error', e => reject(e))
                reader.readAsDataURL(file)
            })
        },
    },

    name: "Global-Dialog-File-Explorer",

    props: {
        context: {
            required: true,
            type: String,
        },
        id: {
            required: true,
            type: Number,
        }
    },

    watch: {
        dialogDelete(status) {
            if (status === false) {
                this.dialogDeleteId = null
            }
        },

        dialogDeleteId(id) {
            if (id !== null) {
                this.dialogDelete = true
            }
        },
    }
}
</script>
