<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
        persistent
        scrollable
        :fullscreen="fullscreen"
        :transition="transition"
    >
        <template v-slot:activator="{ attrs, on }">
            <v-list-item
                dense
                v-bind="attrs"
                v-on="on"
            >
                <v-list-item-avatar>
                    <v-badge
                        :color="(registry.offline !== false) ? 'red' : 'green'"
                        bordered
                        dot
                        offset-x="9"
                        offset-y="9"
                        overlap
                    >
                        <v-avatar
                            class="white--text font-weight-bold"
                            :color="calcColor(registry.ragioneSociale)"
                            size="32"
                            style="font-size: 18px; justify-content: center"
                        >
                            {{ registry.ragioneSociale | firstLetter }}
                        </v-avatar>
                    </v-badge>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="no-ellipsis">
                        {{ registry.ragioneSociale }}
                    </v-list-item-title>

                    <v-list-item-subtitle
                        v-if="registry.issue"
                    >
                        <small class="red--text font-weight-bold">{{ registry.issue }}</small>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <RegistriesMenu
                        :registry="registry"
                    />
                </v-list-item-action>
            </v-list-item>
        </template>

        <v-card
            v-if="registry"
            :tile="fullscreen"
        >
            <v-toolbar
                class="flex-grow-0"
                color="secondary"
                dark
            >
                <v-toolbar-title class="subtitle-1">
                    {{ registry.ragioneSociale }}
                </v-toolbar-title>

                <v-spacer/>

                <v-btn
                    v-if="showBtnCreateActivity"
                    :to="`/registries/${registry.id}/activities/create`"
                    icon
                >
                    <v-icon
                        dark
                    >
                        mdi-forum-outline
                    </v-icon>
                </v-btn>

                <RegistriesMenu
                    :card-context="true"
                    :registry="registry"
                    :show-contacts-helper="false"
                />

                <v-btn
                    icon
                    @click="dialog = false"
                >
                    <v-icon dark>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container class="overflow-y-auto">
                <div
                    v-if="registry.tags.length"
                    style="margin: 15px 15px 5px"
                >
                    <v-chip
                        v-for="(t, i) in registry.tags"
                        :key="i"
                        color="light-blue"
                        :style="(i === 0) ? '' : 'margin-left: 10px'"
                        dark label small
                    >
                        <v-icon
                            left small
                            v-text="'mdi-tag-outline'"
                        />

                        {{ t.text }}
                    </v-chip>
                </div>

                <v-list
                    subheader
                >
                    <v-subheader>Anagrafica</v-subheader>
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="no-ellipsis">
                                {{ registry.ragioneSociale }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="fullname">
                        <v-list-item-avatar/>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ fullname }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-if="registry.pIva"
                    >
                        <v-list-item-avatar/>

                        <v-list-item-content>
                            <v-list-item-title class="no-ellipsis">
                                P.IVA {{ registry.pIva }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <template
                        v-for="(c, i) in registry.contacts.phones"
                    >
                        <v-divider
                            v-if="i > 0"
                            v-bind:key="'PD' + i"
                            inset
                        />

                        <v-list-item :key="'PI' + i">
                            <v-list-item-avatar>
                                <v-icon v-if="i === 0">mdi-phone</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title
                                    v-html="$options.filters.phoneDisplay(c)"
                                />
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn
                                    color="secondary"
                                    :href="'tel:' + $options.filters.phone(c.value)"
                                    icon
                                >
                                    <v-icon>mdi-phone-in-talk</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </template>

                    <template
                        v-for="(c, i) in registry.contacts.emails"
                    >
                        <v-divider
                            v-if="i > 0"
                            v-bind:key="'ED' + i"
                            inset
                        />

                        <v-list-item
                            v-bind:key="'EI' + i"
                        >
                            <v-list-item-avatar>
                                <v-icon v-if="i === 0">mdi-email-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title
                                    v-html="$options.filters.emailDisplay(c)"
                                />
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn
                                    color="secondary"
                                    :href="'mailto:' + c.value"
                                    icon
                                >
                                    <v-icon>mdi-email-edit-outline</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </template>

                    <v-list-item three-line>
                        <v-list-item-avatar>
                            <v-icon>mdi-map-marker-outline</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title
                                class="no-ellipsis"
                                v-html="address"
                            />
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn
                                :href="googleMapsLink"
                                target="_blank"
                                color="secondary"
                                icon
                            >
                                <v-icon>mdi-navigation-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <div v-if="registry.note">
                        <v-subheader>
                            Note
                        </v-subheader>
                        <v-list-item>
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title class="no-ellipsis">
                                    {{ registry.note }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>

                    <div v-if="registry.details && (registry.details.length > 0)">
                        <v-subheader>
                            Dettagli lavorazione
                        </v-subheader>

                        <template
                            v-for="(d, i) in registry.details"
                        >
                            <v-divider
                                v-if="i > 0"
                                :key="'DD' + i"
                                inset
                            />

                            <v-list-item :key="'DI' + i">
                                <v-list-item-avatar/>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ d.keyName }}: {{ d.keyValue }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </div>

                    <div v-if="registry.assets && (registry.assets.length > 0)">
                        <v-subheader>
                            Apparati aziendali
                        </v-subheader>

                        <template
                            v-for="(a, i) in registry.assets"
                        >
                            <v-divider
                                v-if="i > 0"
                                :key="'DD' + i"
                                inset
                            />

                            <v-list-item :key="'DI' + i">
                                <v-list-item-avatar/>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        Linea: <strong>{{ a.line }}</strong> &middot; Q.tà: <strong>{{ a.qty }}</strong><br>
                                        <strong>{{ a.typology }} {{ a.brand }} {{ a.model }}</strong>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </div>
                </v-list>

                <div
                    style="visibility: hidden"
                    v-intersect="onIntersect"
                />
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import RegistriesMenu from '../../components/Registries/Menu.vue'
import {mapActions} from 'vuex'

export default {
    name: 'RegistriesDetailsCard',

    props: {
        registry: Object,
    },

    components: {
        RegistriesMenu,
    },

    data() {
        return {
            dialog: false,
            showBtnCreateActivity: false,
        }
    },

    computed: {
        address() {
            let parts = []

            if (this.registry?.indirizzo) {
                parts.push(`${this.registry.indirizzo.trim()}<br>`)
            }

            if (this.registry?.citta) {
                parts.push(`${this.registry.citta.trim()}, `)
            }

            if (this.registry?.provincia) {
                parts.push(`${this.registry.provincia.trim()} `)
            }

            if (this.registry?.cap) {
                parts.push(`${this.registry.cap.trim()} `)
            }

            parts.push('<br>')

            if (this.registry?.nazione) {
                parts.push(this.registry.nazione.trim())
            }

            return parts.join('')
        },
        fullname() {
            if (this.registry.nome && this.registry.cognome) {
                return this.registry.cognome + ', ' + this.registry.nome
            }

            return null
        },
        fullscreen() {
            return (this.$vuetify.breakpoint.width < 600)
        },
        googleMapsLink() {
            let q = this.registry.indirizzo.trim();

            if (this.registry.cap !== null) {
                q += ',' + this.registry.cap
            }

            if (this.registry.citta !== null) {
                q += ',' + this.registry.citta
            }

            if (this.registry.provincia !== null) {
                q += ',' + this.registry.provincia
            }

            if (this.registry.gps !== null) {
                q += '@' + this.registry.gps
            }

            return 'https://maps.google.com/maps?hl=it&q=' + q.trim()
        },

        transition() {
            return this.fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'
        },
    },

    methods: {
        ...mapActions({
            getRegistry: 'registries/getRegistry',
        }),

        onIntersect (entries, observer, isIntersecting) {
            if (isIntersecting === true) {
                this.showBtnCreateActivity = true
            }
        },

        resize(loop = 0) {
            const header = this.$refs['app-bar'].$el
            const card = this.$refs['app-bar'].$parent.$el

            if (card.offsetWidth !== 0) {
                header.style.width = card.offsetWidth + 'px'
            } else {
                if (loop <= 100) {
                    setTimeout(() => this.resize(++loop), 50)
                }
            }
        },

        calcColor(content) {
            const hash = (str => {
                let hash = 0;

                for (let i = 0, ii = str.length; i < ii; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash)
                }

                return hash
            })(content)

            const c = (hash & 0x00FFFFFF).toString(16)

            return '#' + '00000' . substring(0, 6 - c.length) + c
        },
    },

    filters: {
        emailDisplay(data) {
            return data.value.trim() + ' &middot ' + data.label.trim()
        },

        phone(phone) {
            return phone.toString().replace(/([a-z])+/ig, '').trim()
        },

        phoneDisplay(data) {
            return data.value.toString().replace(/([a-z])+/ig, '').trim() +
                ' &middot ' + data.label.trim()
        },

        firstLetter: (word) =>word.toString().substr(0, 1).trim(),
    },
}
</script>

<style scoped>

</style>
