<template>
    <v-container
        v-if="form"
        class="py-6 px-6"
        tag="form"
    >
        <v-row dense>
            <v-col cols="12">
                <v-autocomplete
                    v-model="typology"
                    :error-messages="typologyErrors"
                    :items="typologies"
                    item-text="text"
                    item-value="id"
                    label="Tipologia *"
                    outlined
                    required
                    @blur="$v.typology.$touch()"
                    @input="$v.typology.$touch()"
                />
            </v-col>

            <v-col cols="12">
                <v-autocomplete
                    v-model="customer"
                    :error-messages="customerErrors"
                    :items="customers"
                    item-text="text"
                    item-value="id"
                    label="Cliente *"
                    outlined
                    required
                    @blur="$v.customer.$touch()"
                    @input="$v.customer.$touch()"
                />
            </v-col>

            <v-col
                v-if="(typology === 60) || (typology === 70)"
                cols="12"
            >
                <v-autocomplete
                    v-if="machines.length > 0"
                    v-model="machine"
                    :error-messages="machineErrors"
                    :items="machines"
                    clearable
                    item-text="text"
                    item-value="id"
                    label="Macchina"
                    @blur="$v.machine.$touch()"
                    @input="$v.machine.$touch()"
                />
            </v-col>
            <v-col
                cols="12"
            >
                <v-dialog
                    v-model="dateAt_dialog"
                    max-width="300"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateAt_formatted"
                            :error-messages="dateAtErrors"
                            label="Data attività *"
                            outlined
                            prepend-icon="mdi-calendar"
                            readonly
                            required
                            v-bind="attrs"
                            @blur="$v.dateAt.$touch()"
                            @input="$v.dateAt.$touch()"
                            v-on="on"
                            @click:prepend="dateAt_dialog = true"
                        />
                    </template>
                    <v-date-picker
                        v-model="dateAt"
                        :first-day-of-week="1"
                        :show-current="$options.datePickerCurrent"
                        :title-date-format="datePickerTitleDateFormat"
                        full-width
                        scrollable
                        @input="dateAt_dialog = false"
                    />
                </v-dialog>
            </v-col>
            <v-col
                cols="6"
            >
                <v-text-field
                    v-model="startedAt"
                    :error-messages="startedAtErrors"
                    label="Inizio *"
                    outlined
                    required
                    type="time"
                    @blur="$v.startedAt.$touch()"
                    @input="$v.startedAt.$touch()"
                />
            </v-col>
            <v-col
                cols="6"
            >
                <v-text-field
                    v-model="finishedAt"
                    :error-messages="finishedAtErrors"
                    label="Fine *"
                    outlined
                    required
                    type="time"
                    @blur="$v.finishedAt.$touch()"
                    @input="$v.finishedAt.$touch()"
                />
            </v-col>

            <v-col cols="12">
                <v-text-field
                    v-model.trim="kmPercorsi"
                    :error-messages="kmPercorsiErrors"
                    append-outer-icon="mdi-plus"
                    label="KM percorsi"
                    max="65535"
                    min="0"
                    outlined
                    prepend-icon="mdi-minus"
                    step="1"
                    type="number"
                    @blur="$v.kmPercorsi.$touch()"
                    @input="$v.kmPercorsi.$touch()"
                    @click:append-outer="incrementer('plus')"
                    @click:prepend="incrementer('minus')"
                />
            </v-col>

            <v-col cols="12">
                <v-textarea
                    v-model.trim="description"
                    :error-messages="descriptionErrors"
                    auto-grow
                    class="auto-grow-max-height"
                    label="Descrizione *"
                    no-resize
                    outlined
                    required
                    rows="1"
                    @blur="$v.description.$touch()"
                    @input="$v.description.$touch()"
                />
            </v-col>
        </v-row>

        <v-subheader class="text-uppercase font-weight-bold pl-0">Tags</v-subheader>
        <v-row dense>
            <v-col
                v-for="(t, i) in tags"
                :key="i"
                cols="6"
                sm="4"
                md="2"

            >
                <v-text-field
                    :value="tags[i].tag"
                    append-icon="mdi-view-list-outline"
                    append-outer-icon="mdi-close"
                    label="Tag"
                    @input="tags[i].tag = $event ? $event.trim() : null"
                    @click:append="getFromTagsList(i)"
                    @click:append-outer="deleteTag(i)"
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col
                class="text-center"
                cols="2"
                offset="10"
            >
                <v-icon
                    color="green"
                    small
                    @click="addTag()"
                    v-text="'mdi-plus-box-outline'"
                />
            </v-col>
        </v-row>

        <portal to="navigation-back">
            <v-btn
                icon
                @click="close"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </portal>

        <portal to="commands">
            <v-btn
                icon
                title="Reset"
                @click="fill"
            >
                <v-icon dark>mdi-reload</v-icon>
            </v-btn>

            <v-btn
                icon
                title="Salva"
                @click="save"
            >
                <v-icon dark>mdi-content-save-outline</v-icon>
            </v-btn>
        </portal>

        <GlobalDialogListSuggestions
            v-if="dialogSuggestions"
            :items="dialogSuggestionsItems"
            :title="dialogSuggestionsTitle"
            @close="dialogSuggestions = false"
            @select="dialogSuggestionsSelect"
        />

        <GlobalOverlayFullscreenIndeterminate
            v-if="saving"
            :caption="savingCaption"
        />
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { maxLength, numeric, required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'
import { filter, find, padStart, reduce } from 'lodash'
import { mapActions, mapState } from 'vuex'
import { DateTime } from 'luxon'
import { Suggestions } from "../../models/_all"
import GlobalDialogListSuggestions from "../../components/Global/Dialog/List/Suggestions"
import GlobalOverlayFullscreenIndeterminate
    from "../../components/Global/Overlay/Fullscreen/Indeterminate"
import { Portal } from "portal-vue";

export default {
    async beforeCreate() {
        this.typologies = await Suggestions.find('activities/typologies')
    },

    beforeMount() {
        this.$store.commit('setToolbarTitle', isNaN(this.id) ? 'Crea' : 'Modifica')
        this.fill()
    },

    components: {
        GlobalDialogListSuggestions,
        GlobalOverlayFullscreenIndeterminate,
        Portal,
    },

    computed: {
        ...mapState({
            registries: ({registries}) => registries.all,
        }),

        customerErrors() {
            const errors = []

            if (!this.$v.customer.$dirty) {
                return errors
            }

            !this.$v.customer.required && errors.push('Questo campo è obbligatorio')

            return errors
        },

        customers() {
            return reduce(this.registries, (registries, r) => {
                registries.push({
                    id: r.id,
                    text: r.ragioneSociale,
                })

                return registries
            }, [])
        },

        data() {
            let typologyName;

            if (this.typology !== null) {
                typologyName = find(this.typologies, t => t.id === this.typology).text
            }

            let elapsedTime

            if ((this.startedAt !== null) && (this.finishedAt !== null)) {
                const startedAt = DateTime.fromFormat(`${this.dateAt} ${this.startedAt}`, 'y-LL-dd HH:mm')
                const finishedAt = DateTime.fromFormat(`${this.dateAt} ${this.finishedAt}`, 'y-LL-dd HH:mm')
                const diff = finishedAt.diff(startedAt, ['hours', 'minutes']).toObject()

                elapsedTime = padStart(diff.hours, 2, '0') + ':' + padStart(diff.minutes, 2, '0')
            }

            return {
                attachments: this.attachments,
                customer: this.customer,
                dateAt: this.dateAt,
                description: this.description,
                elapsedTime,
                finishedAt: this.finishedAt,
                id: this.id,
                kmPercorsi: this.kmPercorsi,
                machine: this.machine,
                startedAt: this.startedAt,
                tags: this.tags,
                typology: this.typology,
                typologyName,
            }
        },

        dateAtErrors() {
            const errors = []

            if (!this.$v.dateAt.$dirty) {
                return errors
            }

            !this.$v.dateAt.required && errors.push('Questo campo è obbligatorio')

            return errors
        },

        dateAt_formatted() {
            return DateTime.fromISO(this.dateAt).toFormat('dd LLLL yyyy')
        },

        descriptionErrors() {
            const errors = []

            if (!this.$v.description.$dirty) {
                return errors
            }

            !this.$v.description.required && errors.push('Questo campo è obbligatorio')

            return errors
        },

        finishedAtErrors() {
            const errors = []

            if (!this.$v.finishedAt.$dirty) {
                return errors
            }

            !this.$v.finishedAt.required && errors.push('Questo campo è obbligatorio')

            return errors
        },

        kmPercorsiErrors() {
            const errors = []

            if (!this.$v.customer.$dirty) {
                return errors
            }

            return errors
        },

        machineErrors() {
            const errors = []

            if (!this.$v.machine.$dirty) {
                return errors
            }

            return errors
        },

        machines() {
            if (this.customer) {
                const registry = filter(this.registries, ({id}) => (id === this.customer))

                if (registry.length > 0) {
                    return reduce(registry[ 0 ].machines, (machines, m) => {
                        machines.push({
                            id: m.id,
                            text: m.matricola,
                        })

                        return machines
                    }, [])
                }
            }

            return []
        },

        startedAtErrors() {
            const errors = []

            if (!this.$v.startedAt.$dirty) {
                return errors
            }

            !this.$v.startedAt.required && errors.push('Questo campo è obbligatorio')

            return errors
        },
        typologyErrors() {
            const errors = []

            if (!this.$v.typology.$dirty) {
                return errors
            }

            !this.$v.typology.required && errors.push('Questo campo è obbligatorio')

            return errors
        },
    },

    data() {
        return {
            activity: null,
            customer: null,
            dateAt: null,
            dateAt_dialog: false,
            description: null,
            dialogSuggestions: false,
            dialogSuggestionsItems: null,
            dialogSuggestionsSelect: null,
            dialogSuggestionsTitle: null,
            finishedAt: null,
            finishedAt_dialog: false,
            form: false,
            id: null,
            kmPercorsi: null,
            machine: null,
            saving: false,
            savingCaption: null,
            startedAt: null,
            startedAt_dialog: false,
            tags: [],
            typologies: [],
            typology: null,
        }
    },

    datePickerCurrent: DateTime.now().toISODate(),

    methods: {
        ...mapActions({
            getActivity: 'activities/getActivity',
            put: 'activities/put',
        }),

        addTag() {
            this.tags.push({
                tag: '',
            })
        },

        async close() {
            if (window.history.length > 1) {
                this.$router.back()
            } else {
                await this.$router.push('/activities')
                    .catch(() => {
                        // I don't what I do
                    })
            }
        },

        datePickerTitleDateFormat(date) {
            return DateTime.fromISO(date).toFormat('dd LLLL')
        },

        deleteTag(index) {
            this.tags = filter(this.tags, (t, idx) => (idx !== index))
        },

        fill() {
            if (this.$route.params.id === undefined) {
                let customer = this.$route.params.customer || null

                if (customer) {
                    customer = Number(customer)
                }

                const oDateTime = DateTime.now()

                this.activity = {
                    attachments: [],
                    dateAt: oDateTime.toISODate(),
                    customer,
                    description: null,
                    finishedAt: oDateTime.toFormat('HH:mm'),
                    id: uuidv4(),
                    kmPercorsi: null,
                    machine: null,
                    startedAt: oDateTime.toFormat('HH:mm'),
                    tags: [],
                    typology: null,
                }
            } else {
                this.getActivity(this.$route.params.id)
                    .then(activity => this.activity = activity)
            }
        },

        async getFromTagsList(index) {
            this.dialogSuggestionsItems = await Suggestions.find('activities/tags')
            this.dialogSuggestionsSelect = e => {
                this.tags[ index ].tag = e
                this.dialogSuggestions = false
            }
            this.dialogSuggestionsTitle = 'Tags disponibili'
            this.dialogSuggestions = true
        },

        incrementer(action) {
            if (action === 'minus') {
                if (this.kmPercorsi === null || this.kmPercorsi === 0) {
                    this.kmPercorsi = 0
                } else {
                    this.kmPercorsi--
                }
            } else if (action === 'plus') {
                if (this.kmPercorsi === null) {
                    this.kmPercorsi = 1
                } else {
                    this.kmPercorsi++
                }
            }
        },

        async save() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.savingCaption = 'Salvataggio in corso'
                this.saving = true

                await this.put({
                    id: this.id,
                    recordset: this.data,
                })

                this.saving = false
                await this.close()
            }
        },
    },

    mixins: [validationMixin],

    name: "Activities-Edit",

    validations: {
        customer: {
            required,
        },

        dateAt: {
            required,
        },

        description: {
            maxLength: maxLength(65535),
            required,
        },

        finishedAt: {
            required,
        },

        kmPercorsi: {
            numeric,
        },

        machine: {
            numeric,
        },

        startedAt: {
            required,
        },

        typology: {
            required,
        },
    },

    watch: {
        activity(collection) {
            if (collection) {
                this.attachments = collection.attachments
                this.dateAt = collection.dateAt
                this.customer = collection.customer
                this.description = collection.description
                this.finishedAt = collection.finishedAt
                this.id = collection.id
                this.kmPercorsi = collection.kmPercorsi
                this.machine = collection.machine
                this.startedAt = collection.startedAt
                this.tags = JSON.parse(JSON.stringify(collection.tags))
                this.typology = collection.typology

                this.form = true
            }
        },

        dialogSuggestions(status) {
            if (status === false) {
                this.dialogSuggestionsItems = null
                this.dialogSuggestionsSelect = null
                this.dialogSuggestionsTitle = null
            }
        },

        kmPercorsi(km) {
            if (km === 0) {
                this.kmPercorsi = null
            }
        },

        saving(status) {
            if (status === false) {
                this.savingCaption = null
            }
        },

        typology(type) {
            if ((type !== 60) && (type !== 70)) {
                this.machine = null
            }
        },
    },
}
</script>

<style lang="scss">
.auto-grow-max-height textarea {
    max-height: 200px !important;
}
</style>
