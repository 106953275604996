<template>
    <div>
        <v-subheader
            class="text-uppercase font-weight-bold"
            inset
        >
            App
        </v-subheader>

        <v-row no-gutters>
            <v-col cols="12" md="6">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-cellphone-arrow-down</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-subtitle>
                            {{ appVersion }} <strong>db-version</strong> {{ databaseVersion }}<br>
                            <v-icon color="primary" small>mdi-update</v-icon>
                            {{ lastUpdate | dateVerbose }}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="d.hasServiceWorker">
                        <v-btn
                            :disabled="!d.appOnline"
                            :loading="swChecking"
                            icon
                            @click="swUpdate"
                        >
                            <v-icon color="primary">mdi-refresh</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-col>

            <v-col cols="12" md="6">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>{{ 'mdiApi' | mdi }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-subtitle>
                            {{ apiVersion }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>

            <v-col cols="12" md="6">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>{{ 'mdiDirectionsFork' | mdi }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-subtitle>
                            {{ isBeta ? 'Beta' : 'Stabile' }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>

            <v-col cols="12" md="6">
                <v-list-item
                    @click="dialogLogout = true"
                >
                    <v-list-item-icon>
                        <v-icon color="red">{{ 'mdiLocationExit' | mdi }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Esci
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Rimuovi il tuo account personale.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>

        <GlobalDialogLogout
            v-if="dialogLogout"
            @close="dialogLogout = false"
        />
    </div>
</template>

<script>
import Database from '../../libraries/dexie'
import { mapState } from 'vuex'
import GlobalDialogLogout from '../../components/Global/Dialog/Logout'
import { serviceWorkers } from "../../libraries/register-service-worker";
import { settings } from "../../libraries/storage";

let sw = null

export default {
    components: {
        GlobalDialogLogout,
    },

    computed: {
        ...mapState({
            d: state => state._default,
        }),
        apiVersion() {
            return settings.get('app', 'api', 'version')
        },
        appVersion() {
            return settings.get('app', 'version')
        },
        isBeta() {
            return (process.env.VUE_APP_BETA === "1");
        },
        lastUpdate() {
            return settings.get('app', 'service-worker', 'last-update')
        },
    },

    async created() {
        sw = await serviceWorkers.get('/service-worker.js')
    },

    data() {
        return {
            databaseVersion: Database.verno,
            dialogLogout: false,
            swChecking: false,
        }
    },

    methods: {
        async swUpdate() {
            if (sw) {
                this.swChecking = true
                await sw.update()
                this.swChecking = false
            }
        },
    },

    name: "About-App",
}
</script>
