<script>
import { mapState } from "vuex";
import { serviceWorkers } from "../libraries/register-service-worker";
import { getMessaging, onMessage } from "firebase/messaging";
import { assign } from "lodash";
import { firebaseApp } from "../libraries/firebase";

let offMessage = null

export default {
    computed: {
        ...mapState({
            hasNotificationEnabled: state => state._default.hasNotificationEnabled,
        }),
    },
    async created() {
        await this.inizialize(this.hasNotificationEnabled)
    },
    methods: {
        async inizialize(hasNotificationEnabled) {
            if (offMessage !== null) {
                offMessage()
            }

            const sw = await serviceWorkers.get('/firebase-messaging-sw.js')

            if (hasNotificationEnabled && sw) {
                const messaging = getMessaging(firebaseApp)

                offMessage = onMessage(messaging, async (payload) => {
                    const {
                        data,
                        notification,
                    } = payload

                    let title = notification?.title || process.env.VUE_APP_SHORT_NAME

                    let options = notification || {}

                    if (data?.actions) {
                        options = assign(options, {
                            actions: JSON.parse(data.actions),
                        })
                    }

                    sw.active?.postMessage({options, title, type: "notification"})
                })
            }
        },
    },
    name: "FcmOnMessage",
    watch: {
        async hasNotificationEnabled(hasNotificationEnabled) {
            await this.inizialize(hasNotificationEnabled)
        },
    },
}
</script>

<template>
    <span></span>
</template>

<style scoped>

</style>
