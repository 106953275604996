<template>
    <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item
                :to="`/activities/${activity.id}/tasks/create`"
            >
                <v-list-item-icon>
                    <v-icon color="purple">{{ 'mdiClipboardListOutline' | mdi }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Genera «Cosa fa fare»
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                @click="toCalendar(activity)"
            >
                <v-list-item-icon>
                    <v-icon color="purple">mdi-calendar-import</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Importa in Calendar
                </v-list-item-title>
            </v-list-item>

            <v-divider/>

            <global-dialog-file-explorer
                :id="activity.id"
                context="activities"
            />

            <v-list-item
                :to="`/activities/${activity.id}`"
            >
                <v-list-item-icon>
                    <v-icon color="blue">mdi-pencil-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Modifica
                </v-list-item-title>
            </v-list-item>

            <v-divider
                v-if="isNaN(activity.id)"
            />

            <global-dialog-confirm
                title="Conferma cancellazione"
                question="Sei sicuro di voler cancellare l'attività? La cancellazione è irreversibile e si perderanno tutti i dati."
                ok-label="Conferma"
                @ok-action="trash(activity.id)"
            />
        </v-list>
    </v-menu>
</template>

<script>
import {mapActions} from 'vuex'

import GlobalDialogConfirm from '../Global/Dialog/Confirm'
import GlobalDialogFileExplorer from '../Global/Dialog/File/Explorer'

export default {
    name: "Activities-Menu",

    props: [
        'activity',
    ],

    components: {
        GlobalDialogConfirm,
        GlobalDialogFileExplorer,
    },

    methods: {
        ...mapActions({
            activitiesTrash: 'activities/trash'
        }),

        /**
         * @param {Object} activity
         */
        toCalendar(activity) {
            const {customer} =  activity

            const query = {
                action: 'TEMPLATE',
            }

            query.text = customer.ragioneSociale + ' (' + activity.typologyName + ')'
            query.details = activity.description

            if (customer.gps !== null) {
                query.location = customer.gps
            } else {
                query.location = customer.indirizzo

                if (customer.cap !== null) {
                    query.location += ' ' + customer.cap
                }

                if (customer.citta !== null) {
                    query.location += ' ' + customer.citta
                }

                if (customer.provincia !== null) {
                    query.location += ' ' + customer.provincia
                }

                if (customer.nazione !== null) {
                    query.location += ' ' + customer.nazione
                }
            }

            if (this.$vuetify.breakpoint.mobile) {
                window.open('https://calendar.google.com/calendar/u/0/gp#~calendar:view=e&' + new URLSearchParams(query).toString(), '_blank')
            } else {
                window.open('https://www.google.com/calendar/event?' + new URLSearchParams(query).toString(), '_blank')
            }
        },

        /**
         * @param {Number|String} id
         */
        trash(id) {
            this.activitiesTrash(id).finally(() => this.dialogDelete = false)
        },
    }
}
</script>
