<template>
    <v-dialog
        :value="true"
        max-width="400"
        persistent
    >
        <v-card>
            <v-toolbar
                color="green darken-4"
                dark
            >
                <v-icon left>{{ 'mdiMapSearch' | mdi }}</v-icon>
                <v-toolbar-title>Google Maps</v-toolbar-title>

                <v-spacer/>

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            @click="$emit('close')"
                            v-on="on"
                        >
                            <v-icon>{{ 'mdiClose' | mdi }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Chiudi</span>
                </v-tooltip>
            </v-toolbar>

            <v-container>
                <v-text-field
                    ref="origin"
                    clearable
                    placeholder="Inserisci una posizione"
                />
            </v-container>

            <v-card-actions>
                <v-spacer/>

                <v-btn
                    color="blue"
                    dark
                    @click="$emit('select', data)"
                >
                    Usa indirizzo
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { reduce } from 'lodash'

export default {
    name: "Global-Dialog-Google-Maps-Autocomplete",

    created() {
        const googleMapApi = document.querySelector('script#google-map-api')
        if (!googleMapApi) {
            window.onGoogleMapsApiInit = () => {
                this.init()
            }

            const url = new URL('https://maps.googleapis.com/maps/api/js')
            url.searchParams.set('callback', 'onGoogleMapsApiInit')
            url.searchParams.set('key', process.env.VUE_APP_GOOGLE_API_KEY)
            url.searchParams.set('libraries', 'places')

            const library = document.createElement('script');
            library.setAttribute('id', 'google-map-api')
            library.setAttribute('src', url.toString())
            library.async = true

            const head = document.querySelector('head')
            if (head) {
                head.appendChild(library)
            }
        } else {
            setTimeout(() => this.init(), 150)
        }
    },

    beforeDestroy() {
        window.google.maps.event.clearListeners(this.GMapsAutocomplete, 'place_changed')
        window.google.maps.event.clearInstanceListeners(this.GMapsAutocomplete)
        this.GMapsAutocomplete = null
    },

    data() {
        return {
            GMapsAutocomplete: null,
            place: null,
        }
    },

    computed: {
        data() {
            if (this.place) {
                const {
                    address_components,
                    geometry
                } = this.place

                const data = reduce(address_components, (carry, {long_name, short_name, types}) => {
                    if (types[0] === "street_number") {
                        carry.numero = long_name
                    }
                    else if (types[0] === 'route') {
                        carry.indirizzo = long_name
                    }
                    else if (types[0] === 'administrative_area_level_3') {
                        carry.citta = long_name
                    }
                    else if (types[0] === 'administrative_area_level_2') {
                        carry.provincia = short_name
                    }
                    else if (types[0] === "administrative_area_level_1") {
                        carry.regione = long_name
                    }
                    else if (types[0] === "country") {
                        carry.nazione = short_name
                    }
                    else if (types[0] === "postal_code") {
                        carry.cap = long_name
                    }

                    return carry
                }, {})

                if (data.numero) {
                    data.indirizzo += ` ${data.numero}`

                    delete data.numero
                }

                data.gps = `${geometry.location.lat()},${geometry.location.lng()}`

                return data
            }

            return null
        },
    },

    methods: {
        init() {
            if (!this.GMapsAutocomplete) {
                this.GMapsAutocomplete = new window.google.maps.places.Autocomplete(this.$refs['origin'].$refs.input, {
                    componentRestrictions: {
                        country: 'it',
                    },
                    fields: ["address_components", "geometry"],
                })

                this.GMapsAutocomplete.addListener('place_changed', () => {
                    this.place = this.GMapsAutocomplete.getPlace()
                })
            }
        },
    }
}
</script>
