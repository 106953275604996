<template>
    <v-list>
        <v-list-item to="/activities">
            <v-list-item-icon>
                <v-icon>{{ 'mdiForumOutline' | mdi }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>Attività</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item to="/tasks">
            <v-list-item-icon>
                <v-icon>{{ 'mdiClipboardListOutline' | mdi }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>Cose da fare</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item to="/registries">
            <v-list-item-icon>
                <v-icon>{{ 'mdiAccountGroupOutline' | mdi }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>Clienti e prospect</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item to="/linklania">
            <v-list-item-icon>
                <v-icon>{{ 'mdiLinkVariant' | mdi }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>Linklania</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider
            v-if="showChatbot"
        />

        <v-list-item
            v-if="showChatbot"
            to="/chatbot"
        >
            <v-list-item-icon>
                <v-icon>{{ 'mdiFaceAgent' | mdi }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Assistenza</v-list-item-title>
        </v-list-item>

        <v-divider/>

        <v-subheader
            class="text-uppercase font-weight-bold"
        >
            Online
        </v-subheader>
        <v-list-item>
            <v-list-item-avatar>
                <img src="falco-srl.png" alt="">
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>Falco S.r.l.</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
                <v-btn
                    href="https://falcosrl.eu"
                    icon
                    target="_blank"
                >
                    <v-icon small>{{ 'mdiOpenInNew' | mdi }}</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>

        <v-divider/>

        <v-list-item to="/about">
            <v-list-item-icon>
                <v-icon>mdi-information-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>Informazioni su</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import { settings } from "../../../../libraries/storage";

export default {
    computed: {
        account() {
            return settings.get('user')
        },

        showChatbot() {
            return [2, 8].includes(this.account.role)
        },
    },
    name: "Partials-App-NavigationDrawer",
}
</script>
