<template>
    <div>
        <v-list v-if="getRegistriesProxy.length">
            <template
                v-for="(registry, index) in getRegistriesProxyPaginated"
            >
                <v-divider
                    v-if="index > 0"
                    :key="'D' + index"
                />

                <registries-details-card
                    :key="index"
                    :registry="registry"
                />
            </template>
        </v-list>
        <GlobalPlaceholderNoContents
            v-else
            :icon-no-search="'mdiAccountGroupOutline'"
            :is-search="filterSearchActive"
            :text-no-search="'Non ci sono ancora anagrafiche'"
        />

        <right-navigation v-model="filterNavigationDrawer">
            <template v-slot:toolbar>
                <v-btn
                    icon
                    to="/registries/create"
                >
                    <v-icon>{{ 'mdiPlus' | mdi }}</v-icon>
                </v-btn>

                <v-btn
                    :disabled="!appOnline"
                    icon
                    @click="refresh()"
                >
                    <v-icon>{{ 'mdiRefresh' | mdi }}</v-icon>
                </v-btn>
            </template>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model.trim="filterSearch"
                        label="Cerca"
                        clearable
                        hide-details
                        outlined
                    />
                </v-col>
                <v-col cols="12">
                    <v-combobox
                        v-model="filterTags"
                        :items="getRegistriesTags"
                        clearable
                        label="Tags"
                        multiple
                        small-chips
                        hide-details
                        outlined
                    />
                </v-col>
                <v-col cols="6">
                    <v-switch
                        v-model="filterCustomers"
                        :label="filterCustomersLabel"
                        hide-details
                    />
                </v-col>
                <v-col cols="6">
                    <v-switch
                        v-model="filterProspects"
                        :label="filterProspectsLabel"
                        hide-details
                    />
                </v-col>
            </v-row>
        </right-navigation>

        <portal to="commands">
            <v-btn
                @click="filterNavigationDrawer = !filterNavigationDrawer"
                icon
            >
                <v-icon>{{ 'mdiDotsVertical' | mdi }}</v-icon>
            </v-btn>
        </portal>

        <GlobalBtnPagination
            v-bind:pagination="pagination"
            v-bind:paginationLoading="paginationLoading"
            v-on:click="paginationOffset += paginationLimit"
            v-intersect="onIntersect"
        />

        <GlobalBtnFabToTop />
    </div>
</template>

<script>
import { capitalize, filter, includes, orderBy, reduce, slice, throttle, uniqBy } from 'lodash'
import { mapState } from 'vuex'
import Registries from "../models/Registries"
import GlobalBtnFabToTop from '../components/Global/Btn/FabToTop'
import GlobalBtnPagination from '../components/Global/Btn/Pagination'
import GlobalPlaceholderNoContents from '../components/Global/Placeholder/NoContents'
import { Portal } from "portal-vue";
import RightNavigation from "../components/RightNavigation.vue";
import RegistriesDetailsCard from "./Registries/DetailsCard.vue"
import { settings } from "../libraries/storage";

export default {
    name: "Registries",

    components: {
        GlobalBtnFabToTop,
        GlobalBtnPagination,
        GlobalPlaceholderNoContents,
        Portal,
        RegistriesDetailsCard,
        RightNavigation,
    },

    beforeRouteEnter(to, from, next) {
        if (!settings.has('sync', 'registries', 'offset_date')) {
            next({
                path: '/sync',
                query: {
                    redirectTo: to.fullPath,
                }
            })
        } else {
            next()
        }
    },

    beforeMount() {
        this.$store.commit('setToolbarTitle', 'Clienti e prospect')

        if (settings.has('registries', 'filters', 'customers')) {
            this.filterCustomers = settings.get('registries', 'filters', 'customers')
        } else if (this.customers.length > 0) {
            this.filterCustomers = true
            this.filterProspects = false
        }

        if (settings.has('registries', 'filters', 'prospects')) {
            this.filterProspects = settings.get('registries', 'filters', 'prospects')
        } else if (this.customers.length === 0) {
            this.filterCustomers = false
            this.filterProspects = true
        }

        if (settings.has('registries', 'filters', 'search')) {
            this.filterSearch = settings.get('registries', 'filters', 'search')
        }

        if (settings.has('registries', 'filters', 'tags')) {
            this.filterTags = settings.get('registries', 'filters', 'tags')
        }
    },

    data() {
        return {
            filterCustomers: false,
            filterNavigationDrawer: false,
            filterProspects: false,
            filterSearch: null,
            filterTags: [],

            paginationLimit: 20,
            paginationLoading: false,
            paginationOffset: 0,
        }
    },

    computed: {
        ...mapState({
            appOnline: state => state._default.appOnline,

            customers: state => state.registries.customers,
            prospects: state => state.registries.prospects,
            registries: state => state.registries.all,
        }),

        filterCustomersLabel() {
            return 'Clienti (' + this.customers.length + ')'
        },
        filterProspectsLabel() {
            return 'Prospect (' + this.prospects.length + ')'
        },
        filterSearchActive() {
            return (
                ((this.filterSearch !== null) && (this.filterSearch.length > 2)) ||
                this.filterCustomers || this.filterProspects ||
                (this.filterTags.length > 0)
            )
        },

        getFilterTags() {
            return reduce(this.filterTags, (filter, {value}) => {
                filter.push(value)

                return filter
            }, [])
        },

        getRegistriesProxy() {
            let collection;

            if (this.filterCustomers && this.filterProspects) {
                collection = this.registries
            } else if (this.filterCustomers) {
                collection = this.customers
            } else {
                collection = this.prospects
            }

            collection = filter(collection, r => r.ragioneSociale !== null)

            if (this.getFilterTags.length) {
                collection = filter(collection, r => {
                    if (r.tags.length) {
                        return reduce(r.tags, (test, {id}) => {
                            if (test === false) {
                                test = includes(this.getFilterTags, id)
                            }

                            return test
                        }, false)
                    } else {
                        return false
                    }
                })
            }

            if (this.filterSearch !== null && this.filterSearch.length > 2) {
                collection = filter(collection, r => {
                    const regex = new RegExp(this.filterSearch, 'is')
                    const test = []

                    test.push(r.ragioneSociale.match(regex) !== null)

                    if (r.nome !== null) {
                        test.push(r.nome.match(regex) !== null)
                    }

                    if (r.cognome !== null) {
                        test.push(r.cognome.match(regex) !== null)
                    }

                    if (r.pIva !== null) {
                        test.push(r.pIva.match(regex) !== null)
                    }

                    if (r.indirizzo !== null) {
                        test.push(r.indirizzo.match(regex) !== null)
                    }

                    if (r.citta !== null) {
                        test.push(r.citta.match(regex) !== null)
                    }

                    if (r.provincia !== null) {
                        test.push(r.provincia.match(regex) !== null)
                    }

                    if (r.note !== null) {
                        test.push(r.note.match(regex) !== null)
                    }

                    return reduce(test, (sum, t) => {
                        if (sum === false) {
                            sum = t
                        }

                        return sum
                    }, false)
                })
            }

            return orderBy(collection, ['ragioneSociale'], ['asc'])
        },
        getRegistriesProxyPaginated() {
            let collection = this.getRegistriesProxy

            collection = slice(collection, 0, this.paginationOffset + this.paginationLimit)

            return collection
        },

        getRegistriesTags() {
            let collection

            if (this.filterCustomers && this.filterProspects) {
                collection = this.registries
            } else if (this.filterCustomers) {
                collection = this.customers
            } else {
                collection = this.prospects
            }

            const tags = reduce(collection, (carry, {tags}) => {
                if (tags.length) {
                    tags.map(({id, text}) => {
                        if (id !== undefined) {
                            carry.push({
                                text: capitalize(text),
                                value: id,
                            })
                        }
                    })
                }

                return carry
            }, [])

            return orderBy(uniqBy(tags, 'value'), ['text'], ['asc'])
        },

        page() {
            return (this.paginationOffset / this.paginationLimit) + 1
        },
        pages() {
            return Math.ceil(this.getRegistriesProxy.length / this.paginationLimit)
        },
        pagination() {
            return this.page < this.pages
        },
    },

    watch: {
        filterCustomers(status) {
            if (!status && !this.filterProspects) {
                this.filterProspects = true
            }

            settings.set('registries', 'filters', 'customers', status)
        },
        filterProspects(status) {
            if (!status && !this.filterCustomers) {
                this.filterCustomers = true
            }

            settings.set('registries', 'filters', 'prospects', status)
        },
        filterSearch(search) {
            if (search !== null) {
                settings.set('registries', 'filters', 'search', search)
            } else {
                settings.del('registries', 'filters', 'search')
            }
        },
        filterTags(tags) {
            if (tags.length) {
                settings.set('registries', 'filters', 'tags', tags)
            } else {
                settings.del('registries', 'filters', 'tags')
            }
        },

        getRegistriesProxyPaginated() {
            this.paginationLoading = false
        },
    },

    methods: {
        /**
         * @param {string} content
         * @returns {string}
         */
        calcColor(content) {
            const hash = (str => {
                let hash = 0;

                for (let i = 0, ii = str.length; i < ii; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash)
                }

                return hash
            })(content)

            const c = (hash & 0x00FFFFFF).toString(16)

            return '#' + '00000' . substring(0, 6 - c.length) + c
        },

        onIntersect: throttle(function (entries, observer, isIntersecting) {
            if ((this.paginationOffset / this.paginationLimit) <= 4) {
                this.paginationLoading = isIntersecting

                if (isIntersecting === true) {
                    this.paginationOffset += this.paginationLimit
                }
            }
        }, 300),

        async refresh() {
            await Registries.toCloud()

            let response
            let first_result = 0

            do {
                response = await Registries.fromCloud(first_result)

                if (typeof response === "number") {
                    first_result = response
                } else if (typeof response !== "boolean") {
                    break
                }
            } while (response !== true)
        },
    },

    filters: {
        /**
         * @param {string} word
         * @returns {string}
         */
        firstLetter: (word) =>word.toString().substr(0, 1).trim(),

        /**
         * @param {string} phone
         * @returns {string}
         */
        phone: (phone) => phone.toString().replace(/([a-z])+/ig, '').trim(),
    },
}
</script>
