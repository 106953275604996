<template>
    <v-container style="padding-bottom: 90px">
        <v-row
            v-if="getTasksProxy.length"
            style="margin-top: 0"
        >
            <v-col
                v-for="(task, i) in getTasksProxyPaginated"
                :key="i"
                :cols="getCols"
            >
                <v-card
                    @click="dialogCardId = task.id"
                >
                    <v-card-text style="padding-bottom: 0">
                        <div>{{ task.titolo.toUpperCase() }}</div>
                        <h2
                            v-if="task.customer !== null"
                            class="text-truncate"
                            style="color: black"
                        >
                            <v-icon
                                color="blue"
                                @click.stop="dialogCustomerId = task.customer.id"
                                v-text="'mdi-open-in-new'"
                            />

                            {{ task.customer.ragioneSociale }}
                        </h2>

                        <div style="margin: 5px 0 5px">
                            <v-chip
                                style="margin-bottom: 5px; margin-right: 5px"
                                class="gray lighten-2"
                                dark label small
                            >
                                <v-icon
                                    left small
                                    v-text="'mdi-calendar'"
                                />

                                {{ task.startDate | dateVerbose }}
                            </v-chip>

                            <v-chip
                                v-if="task.expirationDate !== null"
                                style="margin-bottom: 5px; margin-right: 5px"
                                class="deep-orange lighten-2"
                                dark label small
                            >
                                <v-icon
                                    left small
                                    v-text="$options.filters.mdi('mdiCalendarClock')"
                                />

                                {{ task.expirationDate | dateVerbose }}
                            </v-chip>

                            <v-chip
                                v-if="task.completionDate !== null"
                                style="margin-bottom: 5px; margin-right: 5px"
                                class="green lighten-2"
                                dark label small
                            >
                                <v-icon
                                    left small
                                    v-text="$options.filters.mdi('mdiCalendarLock')"
                                />

                                {{ task.completionDate | dateVerbose }}
                            </v-chip>
                        </div>

                        <div
                            :class="'text--primary multiline-ellipsis ' + ((getCols === 12) ? 'fluid' : 'box')"
                            v-html="task.descrizione"
                        />
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="white"
                            elevation="0"
                            small
                            tile
                            @click.stop="dialogCompleteId = task.id"
                        >
                            <v-icon
                                :class="task.completionDate === null ? 'deep-orange--text' : 'green--text'"
                                left
                                small
                            >
                                {{ (task.completionDate === null ? 'mdiCheckboxBlankOutline' : 'mdiCheck') | mdi }}
                            </v-icon>
                            <span
                                :class="task.completionDate === null ? 'deep-orange--text' : 'green--text'"
                                v-html="task.completionDate === null ? 'In corso' : 'Completata'"
                            />
                        </v-btn>

                        <v-spacer/>

                        <TasksMenu v-bind:task="task"/>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <GlobalPlaceholderNoContents
            v-else
            v-bind:is-search="filterSearchActive"
            v-bind:icon-no-search="'mdiClipboardListOutline'"
            v-bind:text-no-search="'Non hai ancora cose da fare'"
        />

        <right-navigation v-model="filterNavigationDrawer">
            <template v-slot:toolbar>
                <v-btn
                    icon
                    @click="create"
                >
                    <v-icon>{{ 'mdiPlus' | mdi }}</v-icon>
                </v-btn>

                <v-btn
                    :disabled="!appOnline"
                    icon
                    @click="refresh()"
                >
                    <v-icon>{{ 'mdiRefresh' | mdi }}</v-icon>
                </v-btn>
            </template>

            <v-row >
                <v-col cols="12">
                    <v-text-field
                        v-model.trim="filterSearch"
                        clearable
                        hide-details
                        label="Cerca"
                        outlined
                    />
                </v-col>

                <v-col cols="12">
                    <v-combobox
                        v-show="filterCustomers_show"
                        v-model="filterCustomers"
                        :items="getAvailableCustomers"
                        clearable
                        hide-details
                        label="Clienti e prospect"
                        multiple
                        outlined
                        small-chips
                    />
                </v-col>

                <v-col cols="6">
                    <v-switch
                        v-model="filterIncomplete"
                        label="In corso"
                        hide-details
                    />
                </v-col>
                <v-col cols="6">
                    <v-switch
                        v-model="filterCompleted"
                        hide-details
                        label="Completate"
                    />
                </v-col>
            </v-row>
        </right-navigation>

        <portal to="commands">
            <v-btn
                @click="filterNavigationDrawer = !filterNavigationDrawer"
                icon
            >
                <v-icon>{{ 'mdiDotsVertical' | mdi }}</v-icon>
            </v-btn>
        </portal>

        <GlobalBtnPagination
            v-bind:pagination="pagination"
            v-bind:paginationLoading="paginationLoading"
            v-on:click="paginationOffset += paginationLimit"
            v-intersect="onIntersect"
        />

        <GlobalBtnFabToTop/>

        <RegistriesDialogCard
            v-if="dialogCustomer"
            v-bind:id="dialogCustomerId"
            v-on:close="dialogCustomer = false"
        />

        <TasksDialogCard
            v-if="dialogCard"
            v-bind:id="dialogCardId"
            v-on:close="dialogCard = false"
        />

        <TasksDialogComplete
            v-if="dialogComplete"
            v-bind:id="dialogCompleteId"
            v-on:close="dialogComplete = false"
        />
    </v-container>
</template>

<script>
import { filter, includes, orderBy, reduce, slice, throttle } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { DateTime } from 'luxon'
import Tasks from "../models/Tasks"
import GlobalBtnFabToTop from '../components/Global/Btn/FabToTop'
import GlobalBtnPagination from '../components/Global/Btn/Pagination'
import GlobalPlaceholderNoContents from '../components/Global/Placeholder/NoContents'
import RegistriesDialogCard from '../components/Registries/Dialog/Card'
import TasksDialogCard from '../components/Tasks/Dialog/Card'
import TasksDialogComplete from '../components/Tasks/Dialog/Complete'
import TasksMenu from '../components/Tasks/Menu'
import RightNavigation from "../components/RightNavigation.vue";
import { Portal } from "portal-vue";
import { settings } from "../libraries/storage";

export default {
    name: "Tasks",

    components: {
        GlobalBtnFabToTop,
        GlobalBtnPagination,
        GlobalPlaceholderNoContents,
        Portal,
        RegistriesDialogCard,
        RightNavigation,
        TasksDialogCard,
        TasksDialogComplete,
        TasksMenu,
    },

    beforeRouteEnter(to, from, next) {
        if (!settings.has('sync', 'tasks', 'offset_date')) {
            next({
                path: '/sync',
                query: {
                    redirectTo: to.fullPath,
                }
            })
        } else {
            next()
        }
    },

    beforeMount() {
        this.$store.commit('setToolbarTitle', 'Cose da fare')

        if (settings.has('tasks', 'filters', 'complete')) {
            this.filterCompleted = settings.get('tasks', 'filters', 'complete')
        }

        if (settings.has('tasks', 'filters', 'incomplete')) {
            this.filterIncomplete = settings.get('tasks', 'filters', 'incomplete')
        }

        if (settings.has('tasks', 'filters', 'customers')) {
            this.filterCustomers = settings.get('tasks', 'filters', 'customers')
        }

        if (settings.has('tasks', 'filters', 'search')) {
            this.filterSearch = settings.get('tasks', 'filters', 'search')
        }
    },

    data() {
        return {
            dialogCard: false,
            dialogCardId: null,

            dialogComplete: false,
            dialogCompleteId: null,

            dialogCustomer: false,
            dialogCustomerId: null,

            filterNavigationDrawer: false,
            filterCompleted: true,
            filterCustomers: [],
            filterCustomers_show: true,
            filterIncomplete: true,
            filterSearch: null,

            paginationLimit: 10,
            paginationLoading: false,
            paginationOffset: 0,
        }
    },

    computed: {
        ...mapGetters({
            getAvailableCustomers: 'tasks/getAvailableCustomers',
            getVerboseCollection: 'tasks/getVerboseCollection',
        }),
        ...mapState({
            appOnline: state => state._default.appOnline,
        }),

        filterSearchActive() {
            return (
                (this.filterCustomers.length > 0) ||
                ((this.filterSearch !== null) && (this.filterSearch.length > 2)) ||
                this.filterIncomplete ||
                this.filterCompleted
            )
        },

        getCols() {
            let cols = 12

            if (this.$vuetify.breakpoint.width >= 1366) {
                cols = 3
            } else if (this.$vuetify.breakpoint.width >= 1024) {
                cols = 4
            } else if (this.$vuetify.breakpoint.width >= 640) {
                cols = 6
            }

            return cols
        },

        getFilterCustomers() {
            return reduce(this.filterCustomers, (filter, c) => {
                filter.push(c.value)

                return filter
            }, [])
        },

        getTasksProxy() {
            let tasks = this.getVerboseCollection

            if (this.$route.params.customer !== undefined) {
                let customerId = this.$route.params.customer

                if (!isNaN(customerId)) {
                    customerId = parseInt(customerId)
                }

                tasks = filter(tasks, ({customer}) => customer === customerId)
            }

            if (this.filterIncomplete === false) {
                tasks = filter(tasks, ({status}) => parseInt(status) !== 0)
            }

            if (this.filterCompleted === false) {
                tasks = filter(tasks, ({status}) => parseInt(status) !== 1)
            }

            if (this.getFilterCustomers.length) {
                tasks = filter(tasks, ({customer}) => {
                    if (customer !== null) {
                        return includes(this.getFilterCustomers, customer.id)
                    } else {
                        return false
                    }
                })
            }

            if ((this.filterSearch !== null) && (this.filterSearch.length > 2)) {
                tasks = filter(tasks, ({customer, titolo, descrizione}) => {
                    const regex = new RegExp(this.filterSearch, 'is')
                    const test = []

                    if (customer !== null) {
                        test.push(customer.ragioneSociale.match(regex) !== null)
                    }

                    test.push(titolo.match(regex) !== null)
                    test.push(descrizione.match(regex) !== null)

                    return reduce(test, (sum, t) => {
                        if (sum === false) {
                            sum = t
                        }

                        return sum
                    }, false)
                })
            }

            return orderBy(tasks, ['dateAt'], ['desc'])
        },
        getTasksProxyPaginated() {
            let collection = this.getTasksProxy

            collection = slice(collection, 0, this.paginationOffset + this.paginationLimit)

            return collection
        },

        pagination() {
            return this.page < this.pages
        },
        pages() {
            return Math.ceil(this.getTasksProxy.length / this.paginationLimit)
        },
        page() {
            return (this.paginationOffset / this.paginationLimit) + 1
        },
    },

    watch: {
        dialogCard(status) {
            if (status === false) {
                this.dialogCardId = null
            }
        },
        dialogCardId(id) {
            if (id !== null) {
                this.dialogCard = true
            }
        },

        dialogComplete(status) {
            if (status === false) {
                this.dialogCompleteId = null
            }
        },
        dialogCompleteId(id) {
            if (id !== null) {
                this.dialogComplete = true
            }
        },

        dialogCustomer(status) {
            if (status === false) {
                this.dialogCustomerId = null
            }
        },
        dialogCustomerId(id) {
            if (id !== null) {
                this.dialogCustomer = true
            }
        },

        filterCompleted(status) {
            if (status === false && this.filterIncomplete === false) {
                this.filterIncomplete = true
            }

            settings.set('tasks', 'filters', 'complete', status)
        },
        filterCustomers(customers) {
            if (customers.length) {
                settings.set('tasks', 'filters', 'customers', customers)
            } else {
                settings.del('tasks', 'filters', 'customers')
            }
        },
        filterIncomplete(status) {
            if (status === false && this.filterCompleted === false) {
                this.filterCompleted = true
            }

            settings.set('tasks', 'filters', 'incomplete', status)
        },
        filterSearch(search) {
            if (search !== null) {
                settings.set('tasks', 'filters', 'search', search)
            } else {
                settings.del('tasks', 'filters', 'search')
            }
        },
    },

    methods: {
        async create() {
            if (this.$route.params.customer === undefined) {
                await this.$router.push('/tasks/create')
                    .catch(() => {
                        // I don't what I do
                    })
            } else {
                await this.$router.push(`/registries/${this.$route.params.customer}/tasks/create`)
                    .catch(() => {
                        // I don't what I do
                    })
            }
        },

        onIntersect: throttle(function (entries, observer, isIntersecting) {
            if ((this.paginationOffset / this.paginationLimit) <= 4) {
                this.paginationLoading = isIntersecting

                if (isIntersecting === true) {
                    this.paginationOffset += this.paginationLimit
                }
            }
        }, 300),

        async refresh() {
            await Tasks.toCloud()

            let response
            let first_result = 0

            do {
                response = await Tasks.fromCloud(first_result)

                if (typeof response === "number") {
                    first_result = response
                } else if (typeof response !== "boolean") {
                    break
                }
            } while (response !== true)
        },
    },

    filters: {
        /**
         * @param {int} timestamp
         * @returns {string}
         */
        dateVerbose(timestamp) {
            let obj;

            if (timestamp.toString().length > 10) {
                obj = DateTime.fromMillis(parseInt(timestamp))
            } else {
                obj = DateTime.fromSeconds(parseInt(timestamp))
            }

            let verbose = obj.toFormat('d LLL y')

            if (obj.toFormat('HH:mm') !== '00:00') {
                verbose += ' @ ' + obj.toFormat('HH:mm')
            }

            return verbose
        },

        /**
         * @param {string} text
         * @returns {string}
         */
        nl2br(text) {
            return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
        },
    }
}
</script>

<style lang="scss" scoped>
.multiline-ellipsis {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;

    &.box {
        height: 64px;
    }

    &.fluid {
        max-height: 64px;
    }
}
</style>
