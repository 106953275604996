<template>
    <form v-if="form">
        <portal to="navigation-back">
            <v-btn
                icon
                @click="close"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </portal>

        <portal to="commands">
            <v-btn
                icon
                title="Reset"
                @click="fill"
            >
                <v-icon dark>mdi-reload</v-icon>
            </v-btn>

            <v-btn
                icon
                title="Salva"
                @click="save"
            >
                <v-icon dark>mdi-content-save-outline</v-icon>
            </v-btn>
        </portal>

        <v-container>
            <v-row dense>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="customer"
                        :items="customers"
                        clearable
                        item-text="text"
                        item-value="id"
                        label="Cliente"

                        :error-messages="customerErrors"
                        @blur="$v.customer.$touch()"
                        @input="$v.customer.$touch()"
                    />
                </v-col>

                <v-col cols="7">
                    <v-dialog
                        v-model="startDate_dialog"
                        max-width="300"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startDate_formatted"
                                v-bind="attrs"
                                v-on="on"
                                label="Data attività *"
                                readonly
                                required

                                :error-messages="startDateErrors"
                                @blur="$v.startDate.$touch()"
                                @input="$v.startDate.$touch()"

                                prepend-inner-icon="mdi-calendar"
                                @click:prepend-inner="startDate_dialog = true"
                            />
                        </template>
                        <v-date-picker
                            v-model="startDate"
                            :first-day-of-week="1"
                            :show-current="$options.datePickerCurrent"
                            full-width
                            scrollable
                            @input="startDate_dialog = false"
                            :title-date-format="datePickerTitleDateFormat"
                        />
                    </v-dialog>
                </v-col>
                <v-col cols="5">
                    <v-dialog
                        v-model="startDateTime_dialog"
                        max-width="300"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startDateTime"
                                v-bind="attrs"
                                v-on="on"
                                label="Ora attività *"
                                readonly
                                required

                                :error-messages="startDateTimeErrors"
                                @blur="$v.startDateTime.$touch()"
                                @input="$v.startDateTime.$touch()"

                                @click="startDateTime_dialog = true"
                                prepend-inner-icon="mdi-clock-outline"
                                @click:prepend-inner="startDateTime_dialog = true"
                            />
                        </template>
                        <v-time-picker
                            v-model="startDateTime"
                            format="24hr"
                            full-width
                        />
                    </v-dialog>
                </v-col>

                <v-col cols="6">
                    <v-dialog
                        v-model="expirationDate_dialog"
                        max-width="300"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="expirationDate_formatted"
                                v-bind="attrs"
                                v-on="on"
                                label="Data scadenza"
                                readonly
                                required

                                :error-messages="expirationDateErrors"
                                @blur="$v.expirationDate.$touch()"
                                @input="$v.expirationDate.$touch()"

                                prepend-inner-icon="mdi-calendar"
                                @click:prepend-inner="expirationDate_dialog = true"
                            />
                        </template>
                        <v-date-picker
                            v-model="expirationDate"
                            :first-day-of-week="1"
                            :show-current="$options.datePickerCurrent"
                            :min="startDate"
                            full-width
                            scrollable
                            @input="expirationDate_dialog = false"
                            :title-date-format="datePickerTitleDateFormat"
                        />
                    </v-dialog>
                </v-col>
                <v-col cols="6">
                    <v-dialog
                        v-model="expirationDateTime_dialog"
                        max-width="300"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="expirationDateTime"
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                label="Ora scadenza"
                                readonly

                                :error-messages="expirationDateTimeErrors"
                                @blur="$v.expirationDateTime.$touch()"
                                @input="$v.expirationDateTime.$touch()"

                                @click="expirationDateTime_dialog = true"
                                prepend-inner-icon="mdi-clock-outline"
                                @click:prepend-inner="expirationDateTime_dialog = true"
                            />
                        </template>
                        <v-time-picker
                            v-model="expirationDateTime"
                            format="24hr"
                            full-width
                        />
                    </v-dialog>
                </v-col>

                <v-col cols="12">
                    <v-text-field
                        v-model.trim="titolo"
                        :error-messages="titoloErrors"
                        label="Titolo *"
                        required
                        @blur="$v.titolo.$touch()"
                        @input="$v.titolo.$touch()"
                    />
                </v-col>

                <v-col cols="12">
                    <v-textarea
                        v-model.trim="descrizione"
                        auto-grow
                        class="auto-grow-max-height"
                        label="Descrizione *"
                        no-resize
                        required
                        rows="1"

                        :error-messages="descrizioneErrors"
                        @blur="$v.descrizione.$touch()"
                        @input="$v.descrizione.$touch()"
                    />
                </v-col>
            </v-row>
        </v-container>

        <GlobalOverlayFullscreenIndeterminate
            v-if="saving"
            v-bind:caption="savingCaption"
        />
    </form>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {maxLength, required} from 'vuelidate/lib/validators'
import {reduce} from 'lodash'
import {v4 as uuidv4} from 'uuid'
import {mapActions, mapState} from 'vuex'
import {DateTime} from 'luxon'

import GlobalOverlayFullscreenIndeterminate from "../../components/Global/Overlay/Fullscreen/Indeterminate"
import {Portal} from "portal-vue";

export default {
    beforeMount() {
        this.$store.commit('setToolbarTitle', isNaN(this.id) ? 'Crea' : 'Modifica')
        this.fill()
    },

    components: {
        GlobalOverlayFullscreenIndeterminate,
        Portal,
    },

    computed: {
        ...mapState({
            registries: state => state.registries.all,
        }),

        completionDateErrors() {
            const errors = []

            if (!this.$v.completionDate?.$dirty) {
                return errors
            }

            return errors
        },
        customerErrors() {
            const errors = []

            if (!this.$v.customer.$dirty) {
                return errors
            }

            return errors
        },

        customers() {
            return reduce(this.registries, (registries, r) => {
                registries.push({
                    id: r.id,
                    text: r.ragioneSociale,
                })

                return registries
            }, [])
        },

        data() {
            const res = {
                completionDate: this.completionDate,
                customer: this.customer,
                descrizione: this.descrizione,
            }

            if (this.expirationDate !== null) {
                const expirationDateTime = this.expirationDateTime || '00:00'

                res.expirationDate = DateTime.fromFormat(`${this.expirationDate} ${expirationDateTime}`, 'yyyy-LL-dd HH:mm').toSeconds()
            } else {
                res.expirationDate = null
            }

            res.id = this.id
            res.startDate = DateTime.fromFormat(`${this.startDate} ${this.startDateTime}`, 'yyyy-LL-dd HH:mm').toSeconds()
            res.titolo = this.titolo

            return res
        },

        descrizioneErrors() {
            const errors = []

            if (!this.$v.descrizione.$dirty) {
                return errors
            }

            !this.$v.descrizione.required && errors.push('Questo campo è obbligatorio')

            return errors
        },
        expirationDateErrors() {
            const errors = []

            if (!this.$v.expirationDate.$dirty) {
                return errors
            }

            return errors
        },
        expirationDateTimeErrors() {
            const errors = []

            if (!this.$v.expirationDateTime.$dirty) {
                return errors
            }

            return errors
        },
        expirationDate_formatted() {
            if (this.expirationDate !== null) {
                return DateTime.fromISO(this.expirationDate).toFormat('dd LLLL yyyy')
            } else {
                return null
            }
        },
        startDateErrors() {
            const errors = []

            if (!this.$v.startDate.$dirty) {
                return errors
            }

            !this.$v.startDate.required && errors.push('Questo campo è obbligatorio')

            return errors
        },
        startDateTimeErrors() {
            const errors = []

            if (!this.$v.startDateTime.$dirty) {
                return errors
            }

            !this.$v.startDate.required && errors.push('Questo campo è obbligatorio')

            return errors
        },
        startDate_formatted() {
            if (this.startDate) {
                return DateTime.fromISO(this.startDate).toFormat('dd LLLL yyyy')
            }
            return ""
        },
        titoloErrors() {
            const errors = []

            if (!this.$v.titolo.$dirty) {
                return errors
            }

            !this.$v.titolo.required && errors.push('Questo campo è obbligatorio')

            return errors
        },
    },

    data() {
        return {
            task: null,
            form: false,
            saving: false,
            savingCaption: null,

            completionDate_dialog: false,

            expirationDate_dialog: false,
            expirationDateTime_dialog: false,

            startDate_dialog: false,
            startDateTime_dialog: false,

            completionDate: null,
            customer: null,
            descrizione: null,
            expirationDate: null,
            expirationDateTime: null,
            id: null,
            startDate: null,
            startDateTime: null,
            titolo: null,
        }
    },

    datePickerCurrent: DateTime.now().toISODate(),

    methods: {
        ...mapActions({
            getActivity: 'activities/getActivity',
            put: 'tasks/put',
            getTask: 'tasks/getTask',
        }),

        /**
         * @param {string} date
         * @returns {string}
         */
        datePickerTitleDateFormat(date) {
            return DateTime.fromISO(date).toFormat('dd LLLL')
        },

        async close() {
            if (window.history.length > 1) {
                this.$router.back()
            } else {
                await this.$router.push('/tasks')
                    .catch(() => {
                        // I don't what I do
                    })
            }
        },

        async fill() {
            if (!this.$route.params.id) {
                let customer = this.$route.params.customer || null
                let descrizione = ''

                if ((customer !== null) && !isNaN(customer)) {
                    customer = parseInt(customer)
                }

                if (this.$route.params.activity) {
                    let activityId = this.$route.params.activity

                    if ((activityId !== null) && !isNaN(activityId)) {
                        activityId = parseInt(activityId)
                    }

                    const activity = await this.getActivity(activityId)
                    if (activity !== null) {
                        customer = activity.customer
                        descrizione = activity.description

                        this.task = {
                            completionDate: null,
                            customer,
                            descrizione,
                            expirationDate: null,
                            id: uuidv4(),
                            startDate: DateTime.now().toSeconds(),
                            titolo: '',
                        }
                    }
                }
            } else {
                this.task = await this.getTask(this.$route.params.id)
            }

            this.form = true
        },

        save() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.savingCaption = 'Salvataggio in corso'
                this.saving = true

                this.put({
                    id: this.id,
                    recordset: this.data,
                }).then(async () => {
                    this.saving = false
                    await this.$router.push('/tasks')
                        .catch(() => {
                            // I don't what I do
                        })
                })
            }
        },
    },

    mixins: [validationMixin],

    name: "Tasks-Edit",

    validations: {
        customer: {},
        descrizione: {
            maxLength: maxLength(65535),
            required,
        },
        expirationDate: {},
        expirationDateTime: {},
        startDate: {
            required,
        },
        startDateTime: {
            required,
        },
        titolo: {
            maxLength: maxLength(255),
            required,
        },
    },

    watch: {
        descrizione(data) {
            this.descrizione = data?.replace(/ +(?= )/g, '')
        },

        saving(status) {
            if (status === false) {
                this.savingCaption = null
            }
        },

        task(collection) {
            if (collection) {
                this.completionDate = collection.completionDate
                this.customer = collection.customer
                this.descrizione = collection.descrizione

                if (collection.expirationDate === null) {
                    this.expirationDate = null
                    this.expirationDateTime = null
                } else {
                    const expirationDate = DateTime.fromSeconds(collection.expirationDate)
                    this.expirationDate = expirationDate.toISODate()
                    this.expirationDateTime = expirationDate.toFormat('HH:mm')
                }

                this.id = collection.id

                const startDate = DateTime.fromSeconds(collection.startDate)
                this.startDate = startDate.toISODate()
                this.startDateTime = startDate.toFormat('HH:mm')

                this.titolo = collection.titolo
            }
        },
        titolo(data) {
            this.titolo = data?.replace(/ +(?= )/g, '')
        },
    },
}
</script>

<style lang="scss">
.auto-grow-max-height textarea {
    max-height: 200px !important;
    overflow-y: auto;
}
</style>
