<template>
    <v-app>
        <v-app-bar
            v-if="!fullscreen"
            id="app-bar"
            app
            color="primary"
            dark
        >
            <portal-target
                name="navigation-back"
                slim
            >
                <v-app-bar-nav-icon
                    @click="navigationDrawer = !navigationDrawer"
                />
            </portal-target>

            <v-toolbar-title
                class="subtitle-1"
                v-html="appToolbarTitle"
            />

            <v-spacer />

            <v-btn
                v-longpress:1500="forceRefresh"
                :disabled="!appOnline"
                icon
            >
                <v-icon
                    v-text="appBarStatusIcon"
                />
            </v-btn>

            <portal-target
                name="commands"
            />

            <push-component
                stealth
            />
        </v-app-bar>

        <v-navigation-drawer
            v-if="!fullscreen"
            v-model="navigationDrawer"
            app
            temporary
        >
            <template v-slot:prepend>
                <v-card
                    v-if="user"
                    dark flat tile
                >
                    <v-img
                        style="height: 120px"
                        src="bg.jpg"
                    >
                        <v-list-item
                            style="margin-top: 45px"
                            two-line
                        >
                            <v-list-item-avatar
                                color="white"
                                size="48"
                            >
                                <img src="/images/maskable_icon_x192.png" alt="">
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                    {{ user.ragioneSociale }}
                                </v-list-item-title>

                                <v-list-item-subtitle class="font-weight-bold">
                                    {{ user.roleName }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-img>
                </v-card>
            </template>

            <PartialsAppNavigationMenu/>
        </v-navigation-drawer>

        <v-main>
            <v-slide-x-transition hide-on-leave>
                <router-view />
            </v-slide-x-transition>
        </v-main>

        <v-banner
            :icon="appOnline ? 'mdi-wifi' : 'mdi-wifi-off'"
            :icon-color="appOnline ? 'green' : 'orange'"
            :value="connectionStatus"
            class="v-banner-connection-status"
            dark
            single-line
            sticky
        >
            {{ appOnline ? 'Connessione Internet ristabilita' : 'Connessione Internet assente' }}
        </v-banner>

        <fcm-on-message/>
    </v-app>
</template>

<script>
import { version as swVersion } from '../package.json'
import update from "./mixins/update"
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'vuex'
import { DateTime, Settings } from "luxon"
import { findIndex } from "lodash"
import axios from "./libraries/axios"
import { Activities, Registries, Suggestions, SuggestionsContexts, Tasks } from './models/_all'
import PartialsAppNavigationMenu from './components/Partials/App/Navigation/Menu'
import { settings } from "./libraries/storage";
import { serviceWorkers } from "./libraries/register-service-worker";
import colors from "vuetify/lib/util/colors";
import { PortalTarget } from "portal-vue";
import Bugsnag from "@bugsnag/js";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "./libraries/firebase";
import FcmOnMessage from "./views/FcmOnMessage.vue";
import PushComponent from "./views/About/PushComponent.vue";

let deamonInterval = null
let swInterval = null

export default {
    beforeDestroy() {
        window.removeEventListener('online', () => this.onAppOnline(true))
        window.removeEventListener('offline', () => this.onAppOffline())

        if (deamonInterval) {
            clearInterval(deamonInterval)
            deamonInterval = null
        }

        if (swInterval) {
            clearInterval(swInterval)
            swInterval = null
        }
    },

    components: {
        PushComponent,
        FcmOnMessage,
        PartialsAppNavigationMenu,
        PortalTarget,
    },

    computed: {
        ...mapState({
            activitiesHasDirty: state => state.activities.hasDirty,
            appOnline: state => state._default.appOnline,
            appSynching: state => state._default.synching,
            appThemeColor: state => state._default.themeColor,
            appToolbarTitle: state => state._default.toolbarTitle,
            registriesHasDirty: state => state.registries.hasDirty,
            remoteConfig: state => state._default.remoteConfig,
            tasksHasDirty: state => state.tasks.hasDirty,
            user: state => state.user.user,
        }),
        appBarStatusIcon() {
            if (this.appOnline) {
                if (this.appSynching || this.deamonBusy) {
                    return 'mdi-cloud-sync-outline'
                } else if (this.hasDirty) {
                    return 'mdi-cloud-alert'
                } else {
                    return 'mdi-cloud-check-outline'
                }
            } else {
                if (this.hasDirty) {
                    return 'mdi-cloud-alert'
                } else {
                    return 'mdi-cloud-off-outline'
                }
            }
        },
        fullscreen() {
            return ['/login', '/sync'].includes(this.$route.path)
        },
        hasDirty() {
            return this.activitiesHasDirty ||
                this.registriesHasDirty ||
                this.tasksHasDirty
        },
    },

    async created() {
        const sw = await serviceWorkers.get('/service-worker.js')
        this.$store.commit('hasServiceWorkerStatus', sw !== null)

        if (navigator.onLine) {
            this.onAppOnline(false)
        } else {
            this.onAppOffline()
        }

        window.addEventListener('online', () => this.onAppOnline(true))
        window.addEventListener('offline', () => this.onAppOffline())

        if (!settings.has('app', 'uuid')) {
            settings.set('app', 'uuid', null, uuidv4())
        }

        if (!settings.has('app', 'service-worker', 'last-update')) {
            settings.set('app', 'service-worker', 'last-update', Date.now())
        }

        if (
            !settings.has('app', 'version') ||
            (settings.get('app', 'version') === 'update') ||
            !this.$store.state.hasServiceWorker
        ) {
            settings.set('app', 'version', null, swVersion)
        }

        Settings.defaultLocale = 'it'

        this.$store.commit('setThemeColor', null)

        await Promise.all([
            this.$store.dispatch('activities/loader'),
            this.$store.dispatch('registries/loader'),
            this.$store.dispatch('tasks/loader'),
        ])

        deamonInterval = setInterval(() => this.deamon(), 5 * 60 * 1000)

        Object.keys(localStorage)
            .forEach(item => {
                if (item.startsWith('@falco/')) {
                    localStorage.removeItem(item)
                }
            })

        if (await caches.has("workbox-precache-v2-https://agents.falcosrl.eu/")) {
            await caches.delete("workbox-precache-v2-https://agents.falcosrl.eu/")

            window.location.reload()
        }

        await fetchAndActivate(remoteConfig)
        this.$store.commit('setRemoteConfig', JSON.parse(getValue(remoteConfig, 'sync').asString()))
    },

    data() {
        return {
            connectionStatus: false,
            deamonBusy: false,
            dialogLogout: false,
            navigationDrawer: false,
        }
    },

    methods: {
        async _activities() {
            try {
                const {
                    single,
                } = this.remoteConfig

                const {
                    activities,
                } = single

                if (settings.has('sync', 'activities', 'offset_date')) {
                    const lastSync = DateTime.fromSeconds(settings.get('sync', 'activities', 'offset_date'))

                    if (lastSync.diffNow('milliseconds').milliseconds > activities) {
                        let response
                        let first_result = 0

                        do {
                            response = await Activities.fromCloud(first_result)

                            if (typeof response === "number") {
                                first_result = response
                            }
                        } while (response !== true)
                    }
                }
            } catch (e) {
                console.log('_registries', e)
            }
        },

        async _registries() {
            try {
                const {
                    single,
                } = this.remoteConfig

                const {
                    registries,
                } = single


                if (settings.has('sync', 'registries', 'offset_date')) {
                    const lastSync = DateTime.fromSeconds(settings.get('sync', 'registries', 'offset_date'))

                    if (lastSync.diffNow('milliseconds').milliseconds > registries) {
                        let response
                        let first_result = 0

                        do {
                            response = await Registries.fromCloud(first_result)

                            if (typeof response === "number") {
                                first_result = response
                            }
                        } while (response !== true)
                    }
                }
            } catch (e) {
                console.log('_registries', e)
            }
        },

        async _suggestions() {
            try {
                const {
                    single,
                } = this.remoteConfig

                const {
                    suggestions,
                } = single

                for (const context in suggestions) {
                    const timeout = suggestions[ context ]


                    if (settings.has('sync', context, 'offset_date')) {
                        const lastSync = DateTime.fromSeconds(settings.get('sync', context, 'offset_date'))

                        if (lastSync.diffNow('milliseconds').milliseconds > timeout) {
                            if (context === "linklania") {
                                const response = await axios.get('https://falcosrl.eu/wp-json/fapwa/v2/linklania')

                                if (response) {
                                    const {
                                        data,
                                        status,
                                    } = response

                                    if ((status === 200) && (data.returnCode === 200)) {
                                        const {
                                            returnData,
                                            offset_date,
                                        } = data

                                        await Suggestions.truncate(context)
                                        await this.put({
                                            context,
                                            data: returnData,
                                        })

                                        settings.set('sync', context, 'offset_date', offset_date)
                                    }
                                }
                            } else {
                                const index = findIndex(SuggestionsContexts, ({context: _c}) => _c === context)

                                if (index !== -1) {
                                    await Suggestions.fromCloud(index)
                                }
                            }
                        }
                    }
                }
            } catch (e) {
                console.log('_registries', e)
            }
        },

        async _tasks() {
            try {
                const {
                    single,
                } = this.remoteConfig

                const {
                    tasks,
                } = single

                if (settings.has('sync', 'tasks', 'offset_date')) {
                    const lastSync = DateTime.fromSeconds(settings.get('sync', 'tasks', 'offset_date'))

                    if (lastSync.diffNow('milliseconds').milliseconds > tasks) {
                        let response
                        let first_result = 0

                        do {
                            response = await Tasks.fromCloud(first_result)

                            if (typeof response === "number") {
                                first_result = response
                            }
                        } while (response !== true)
                    }
                }
            } catch (e) {
                console.log('_registries', e)
            }
        },

        async deamon() {
            if (this.appOnline && !this.deamonBusy && !this.hasDirty) {
                try {
                    this.deamonBusy = true

                    await this._registries()
                    await Promise.all([
                        this._activities(),
                        this._tasks(),
                        this._suggestions(),
                    ])
                } catch (reason) {
                    console.error(reason)

                    Bugsnag.notify(reason, event => {
                        event.context = 'App/deamon'
                    })
                } finally {
                    this.deamonBusy = false
                }
            }
        },

        async forceRefresh() {
            if (this.appOnline) {
                settings.del('sync', '%')

                await this.$router.push('/sync')
                    .catch(() => {
                        // I don't what I do
                    })
            }
        },

        onAppOffline() {
            this.$store.commit('appStatus', false)

            this.connectionStatus = true

            setTimeout(() => this.connectionStatus = false, 2.5 * 1000)
        },

        onAppOnline(notification = true) {
            this.$store.commit('appStatus', true)

            if (notification === true) {
                this.connectionStatus = true

                setTimeout(() => this.connectionStatus = false, 2.5 * 1000)
            }
        },

        async solveDirties() {
            if (this.appOnline && !this.deamonBusy && this.hasDirty) {
                try {
                    this.deamonBusy = true

                    if (this.registriesHasDirty) {
                        await Registries.toCloud()
                    }

                    if (this.activitiesHasDirty) {
                        await Activities.toCloud()
                    }

                    if (this.tasksHasDirty) {
                        await Tasks.toCloud()
                    }
                } catch (reason) {
                    console.error(reason)

                    Bugsnag.notify(reason, event => {
                        event.context = 'App/solveDirties'
                    })
                } finally {
                    this.deamonBusy = false
                }
            }
        },
    },

    mixins: [update],

    name: 'App',

    watch: {
        async appOnline(appOnline) {
            if (appOnline) {
                await this.solveDirties()
            }
        },

        appThemeColor(themeColor) {
            let immediate = false
            const meta = document.querySelector('meta[name=theme-color]')

            if ((typeof themeColor === "string") && themeColor.startsWith('~')) {
                immediate = true
                themeColor = themeColor.substring(1)
            }

            if (meta !== null) {
                if ((themeColor === colors.red.darken4) || immediate) {
                    meta.content = themeColor
                } else {
                    const fromColor = parseInt(meta.content.replace('#', ''), 16)
                    const toColor = parseInt(themeColor.replace('#', ''), 16)

                    let r1 = fromColor >> 16
                    let g1 = fromColor >> 8 & 0xff
                    let b1 = fromColor & 0xff

                    const r2 = toColor >> 16
                    const g2 = toColor >> 8 & 0xff
                    const b2 = toColor & 0xff

                    const duration = 10
                    const steps = 500 / duration

                    const rStep = (r2 - r1) / steps
                    const gStep = (g2 - g1) / steps
                    const bStep = (b2 - b1) / steps

                    let currentStep = 0

                    const timer = setInterval(() => {
                        if (currentStep >= steps) {
                            clearInterval(timer)
                        } else {
                            r1 += rStep
                            g1 += gStep
                            b1 += bStep

                            meta.content = '#' + ((1 << 24) + (Math.round(r1) << 16) + (Math.round(g1) << 8) + Math.round(b1)).toString(16).slice(1)

                            currentStep++
                        }
                    }, duration)
                }
            }
        },

        appToolbarTitle(title) {
            const tag = document.getElementsByTagName('title').item(0)

            tag.innerText = title + ' ~ ' + process.env.VUE_APP_NAME
        },

        fullscreen(fullscreen) {
            if (fullscreen) {
                this.$store.commit('setThemeColor', '#ffffff')
            } else {
                this.$store.commit('setThemeColor', null)
            }
        },

        async hasDirty(hasDirty) {
            if (hasDirty) {
                await this.solveDirties()
            }
        },
    },
};
</script>

<style scoped>
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
    height: inherit;
}

.v-banner-connection-status {
    background-color: #1e1e1e !important;
    bottom: 0 !important;
    position: fixed !important;
    top: unset !important;
    width: 100% !important;
    z-index: 10000 !important;
}
</style>
